import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink,useNavigate, useLocation } from 'react-router-dom';
import PropTypes from "prop-types";

import {EcommerceAccountPersonalView, EcommerceProductsView} from 'src/sections/_e-commerce/view';
import { LoginBackgroundView } from 'src/sections/auth/view';

import jwt_decode from 'jwt-decode';
import {AppBar} from "@mui/material";
import HomePage from "../HomePage";



// login and disconnect in ./layout/main/heades/header.js
const AuthHandler = () => {

    const [jwt, setJwt] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);
    const [isValidJwt, setIsValidJwt] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [isLogged, setIsLogged] = useState(false);

    const navigate = useNavigate();
    const [loginFailed, setLoginFailed] = useState([false,""]);





    // const locLogout = searchParams.get('logout');


    // sessionStorage.setItem('logout', locLogout);

    // const [logout, setLogout] = useState(false);

    // const location = useLocation();
    // const locLogout = location.state?.logout;
    // if (locLogout) {
    //     setLogout(true);
    // }

    //
    // // const [logout, setLogout] = useState(false);
    //
    // const location = useLocation();
    // console.log("location: ", location);
    // const searchParams = new URLSearchParams(location.search);
    // const locLogout = searchParams.get('logout');
    // console.log("locLogout: ", locLogout);
    // sessionStorage.setItem('logout', locLogout);
    //
    // // setLogout(locLogout)
    // // if (locLogout) {
    // //     setLogout(false);
    // // } else {
    // //     setLogout(true);
    // // }

    const fetchJwt = async (email, password) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_MAP_API}/api/token/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (response.ok) {
                const data = await response.json();
                const token = data.access;
                const refreshTokenReceived = data.refresh;

                if (token && refreshTokenReceived) {
                    sessionStorage.setItem('jwt', token);
                    localStorage.setItem('jwt', token);
                    sessionStorage.setItem('refreshToken', refreshTokenReceived);
                    localStorage.setItem('refreshToken', refreshTokenReceived);
                    setJwt(token);
                    setRefreshToken(refreshTokenReceived);
                    setIsValidJwt(true);
                    sessionStorage.setItem('logged', 'true');
                    localStorage.setItem('logged', 'true');
                } else {
                    console.error('Error: JWT or refresh token not received');
                }
            } else {
                console.error('Error: Invalid credentials');
                setLoginFailed([true, "Login Failed: Invalid credentials"]);

            }
        } catch (error) {
            console.error('Error during login:', error);
        }


    };
    // console.log("loginFailed: ", loginFailed);

    const validateJwt = useCallback(async (token) => {
        try {
            // Decode the JWT token
            const decodedToken = jwt_decode(token);
            const currentTime = Math.floor(Date.now() / 1000);

            // Check if the token is expired
            const isTokenExpired = currentTime > decodedToken.exp;

            // If the token is expired, attempt to refresh it
            if (isTokenExpired) {
                const response = await fetch(`${process.env.REACT_APP_MAP_API}/api/token/verify/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ refreshToken }),
                });

                if (response.ok) {
                    const data = await response.json();
                    const newToken = data.access;

                    if (newToken) {
                        sessionStorage.setItem('jwt', newToken);
                        localStorage.setItem('jwt', newToken);
                        sessionStorage.setItem('logged', 'true');
                        localStorage.setItem('logged', 'true');
                        setJwt(newToken);
                        setIsValidJwt(true);
                    } else {
                        console.error('Error: JWT token not received');
                        setIsValidJwt(false);
                    }
                } else {
                    console.error('Error: Refresh token is invalid');
                    setIsValidJwt(false);
                }
            } else {
                setIsValidJwt(true);
            }
        } catch (error) {
            console.error('Error during JWT validation:', error);
            setIsValidJwt(false);
        }
    }, [refreshToken]);


    // const disconnect = () => {
    //     sessionStorage.removeItem('jwt');
    //     sessionStorage.removeItem('refreshToken');
    //     sessionStorage.removeItem('isLogged');
    //     setJwt(null);
    //     setRefreshToken(null);
    //     setIsValidJwt(false);
    //     setIsLogged(false);
    // };


    useEffect(() => {

        const storedJwt = sessionStorage.getItem('jwt');
        const logoutStatus = sessionStorage.getItem('logged');
        const loginAction = sessionStorage.getItem('login');
        if (logoutStatus === 'false' && storedJwt) {
            setRefreshToken(null);
            setIsValidJwt(false);
            setIsLogged(false);
            // sessionStorage.setItem('logged', 'false');
            console.log("logoutStatus: ", logoutStatus);
            sessionStorage.removeItem('jwt');
            localStorage.removeItem('jwt');
            sessionStorage.removeItem('refreshToken');
            localStorage.removeItem('refreshToken');
            navigate('/'); // Replace with the desired route
        } else if (storedJwt) {
            setJwt(storedJwt);
            validateJwt(storedJwt);
            // setLogged(true);
            // sessionStorage.setItem('logged', 'true');
            setIsLogged(true);
            sessionStorage.setItem('logged', 'true');
            localStorage.setItem('logged', 'true');
            navigate('/'); // Replace with the desired route
        } else if (loginAction === 'true') {
            navigate('/auth/login-background'); // Replace with the desired route
        }
            // } else {
            //     // setIsLoading(false);
            //     // setLogged(false);
            //     // sessionStorage.setItem('logged', 'false');
            //     navigate('/auth/login-background'); // Replace with the desired route
            //     // navigate('/'); // Replace with the desired route
            // }







        // if (logout) {
        //     // Perform logout-related tasks
        //     console.log('User logged out');
        //     sessionStorage.removeItem('jwt');
        //     sessionStorage.removeItem('refreshToken');
        //     sessionStorage.removeItem('isLogged');
        //     setJwt(null);
        //     setRefreshToken(null);
        //     setIsValidJwt(false);
        //     setIsLogged(false);
        // }
    }, [validateJwt, navigate]);


    // useEffect(() => {
    //     const storedJwt = sessionStorage.getItem('jwt');
    //
    //     if (storedJwt) {
    //         setJwt(storedJwt);
    //         validateJwt(storedJwt).then((isValid) => {
    //             if (isValid) {
    //                 setIsLoading(false);
    //                 setIsLogged(true);
    //                 setIsValidJwt(true);
    //                 sessionStorage.setItem('isLogged', 'true');
    //                 navigate('/e-commerce/products/'); // Replace with the desired route
    //
    //             } else {
    //                 setIsLoading(false);
    //                 setIsValidJwt(false);
    //                 sessionStorage.setItem('isLogged', 'false');
    //                 navigate('/auth/login-background'); // Replace with the desired route
    //             }
    //         });
    //     } else {
    //         setIsLoading(false);
    //         setIsValidJwt(false);
    //     }
    // }, [validateJwt, navigate]);


    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }

    // AuthHandler.propTypes = {
    //     locLogout: PropTypes.bool
    //
    // }

    // function renderContent() {
    //
    //     if
    //
    //     // if (!isLogged && !isValidJwt) {
    //     //     // return <EcommerceAccountPersonalView />;
    //     //     return <RouterLink to="/" />;
    //     // }
    //     // return <LoginBackgroundView onLogin={fetchJwt} />;
    //
    // }

    function renderContent() {
        if (sessionStorage.getItem('login') || localStorage.getItem('login')) {
            sessionStorage.setItem('login', 'false');
            localStorage.setItem('login', 'false');
            return <LoginBackgroundView onLogin={fetchJwt} loginFailed={loginFailed}/>;

        }
        return <HomePage /> ;

        // return <LoginBackgroundView onLogin={fetchJwt} />;
    }



    // return (
    //     <>
    //         {logout ? (
    //             <EcommerceProductsView />
    //         ) : isValidJwt ? (
    //             // <EcommerceAccountPersonalView />
    //             <EcommerceProductsView />
    //         ) : (
    //             <LoginBackgroundView onLogin={fetchJwt} />
    //         )}
    //     </>
    // );



    return <>{renderContent()}</>;
    // return <LoginBackgroundView onLogin={fetchJwt} />;
};

export default AuthHandler;




