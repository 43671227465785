import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useState, useEffect, useCallback } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';
// @mui
import { Stack, Drawer, Button, Collapse, Typography, TextField } from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import VerticalAlignCenterOutlinedIcon from '@mui/icons-material/VerticalAlignCenterOutlined';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';

import {width} from "@mui/system";
// hooks
import useResponsive from 'src/hooks/useResponsive';
// config
import { NAV } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';
//
import {
    // EcommerceFilterTag,
    EcommerceFilterBrand,
    EcommerceFilterWidth,
    EcommerceFilterLength,
    EcommerceFilterThickness,
    // EcommerceFilterPrice,
    // EcommerceFilterStock,
    // EcommerceFilterRating,
    EcommerceFilterCategory,
    // EcommerceFilterShipping,
} from './components';
import Label from "../../../../components/label";




// ----------------------------------------------------------------------



// const BRAND_OPTIONS = ['Apple', 'Samsung', 'Xiaomi', 'Honor'];
// const BRAND_OPTIONS = [];


const fetchUniqueCategoryCount = async () => {

  // const jwtToken = sessionStorage.getItem('jwt');
    const jwtToken = localStorage.getItem('jwt');
  const headers = {
    'Content-Type': 'application/json',
  };
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  } else {
    console.warn('No JWT token found in local storage. Sending request without Authorization header.');
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_MAP_API}/api/products/unique_category_and_count/`,
        {
            method: 'GET',
            headers,
        });
    const data = await response.json();
    // console.log("getCategoryCount:", data);

    return data;

  } catch (error) {
    console.error("Error fetching unique category and Count:", error);
    return [];
  }
};


const fetchUniqueBrands = async (categoryName, widthName) => {

  // const jwtToken = sessionStorage.getItem('jwt');
    const jwtToken = localStorage.getItem('jwt');
  const headers = {
    'Content-Type': 'application/json',
  };
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  } else {
    console.warn('No JWT token found in local storage. Sending request without Authorization header.');
  }
  try {
    // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/`);
    // if (storedCategory === null || storedCategory === 'All' || storedCategory === '') {
    // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/`);

    // const apiUrl = categoryName === 'all' || categoryName === null || categoryName === '' || categoryName === undefined
    //     ? 'http://127.0.0.1:8000/api/products/unique_brands/'
    //     : `http://127.0.0.1:8000/api/products/unique_brands/?category=${categoryName}`;

      // let apiUrl = `${process.env.REACT_APP_MAP_API}/api/products/unique_brands/`;
      let apiUrl = `${process.env.REACT_APP_MAP_API}/api/products/unique_brands_and_count/`;


      // const apiUrl = categoryName === 'all' || categoryName === null || categoryName === '' || categoryName === undefined
      //     // ? 'http://127.0.0.1:8000/api/products/unique_width/'
      //     // : `http://127.0.0.1:8000/api/products/unique_width/?category=${categoryName}`;
      //     ? `${process.env.REACT_APP_MAP_API}/api/products/unique_brands_and_count/`
      //     : `${process.env.REACT_APP_MAP_API}/api/products/unique_brands_and_count/?category=${categoryName}`;

      // Checking if both category and width are defined.
      if (categoryName && widthName) {
          apiUrl += `?category=${categoryName}&width=${widthName}`;
      } else if (categoryName) { // Only category is defined.
          apiUrl += `?category=${categoryName}`;
      } else if (widthName) { // Only width is defined.
          apiUrl += `?width=${widthName}`;
      }


    const response = await fetch(apiUrl,
        {
            method: 'GET',
            headers,
        });

    // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/?category=${name === 'All' ? '' : name}`);
    const data = await response.json();
    return data;
    // } else {
    //   const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/?category=${storedCategory === 'All' ? '' : storedCategory}`);
    //   const data = await response.json();
    //   return data;
    // }

  } catch (error) {
    console.error("Error fetching unique brands:", error);
    return [];
  }
};


const fetchUniqueWidth = async (categoryName) => {

  // const jwtToken = sessionStorage.getItem('jwt');
  const jwtToken = localStorage.getItem('jwt');
  const headers = {
    'Content-Type': 'application/json',
  };
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  } else {
    console.warn('No JWT token found in local storage. Sending request without Authorization header.');
  }
  try {
    // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/`);
    // if (storedCategory === null || storedCategory === 'All' || storedCategory === '') {
    // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/`);

    const apiUrl = categoryName === 'all' || categoryName === null || categoryName === '' || categoryName === undefined
        // ? 'http://127.0.0.1:8000/api/products/unique_width/'
        // : `http://127.0.0.1:8000/api/products/unique_width/?category=${categoryName}`;
        ? `${process.env.REACT_APP_MAP_API}/api/products/unique_width_and_count/`
        : `${process.env.REACT_APP_MAP_API}/api/products/unique_width_and_count/?category=${categoryName}`;

    const response = await fetch(apiUrl,
        {
          method: 'GET',
          headers,
        });

    // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/?category=${name === 'All' ? '' : name}`);
    const data = await response.json();
    return data;
    // } else {
    //   const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/?category=${storedCategory === 'All' ? '' : storedCategory}`);
    //   const data = await response.json();
    //   return data;
    // }

  } catch (error) {
    console.error("Error fetching unique Width:", error);
    return [];
  }
};


const fetchUniqueLength = async (categoryName) => {

    // const jwtToken = sessionStorage.getItem('jwt');
    const jwtToken = localStorage.getItem('jwt');
    const headers = {
        'Content-Type': 'application/json',
    };
    if (jwtToken) {
        headers.Authorization = `Bearer ${jwtToken}`;
    } else {
        console.warn('No JWT token found in local storage. Sending request without Authorization header.');
    }
    try {
        // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/`);
        // if (storedCategory === null || storedCategory === 'All' || storedCategory === '') {
        // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/`);

        const apiUrl = categoryName === 'all' || categoryName === null || categoryName === '' || categoryName === undefined
            // ? 'http://127.0.0.1:8000/api/products/unique_width/'
            // : `http://127.0.0.1:8000/api/products/unique_width/?category=${categoryName}`;
            ? `${process.env.REACT_APP_MAP_API}/api/products/unique_length_and_count/`
            : `${process.env.REACT_APP_MAP_API}/api/products/unique_length_and_count/?category=${categoryName}`;

        const response = await fetch(apiUrl,
            {
                method: 'GET',
                headers,
            });

        // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/?category=${name === 'All' ? '' : name}`);
        const data = await response.json();
        return data;
        // } else {
        //   const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/?category=${storedCategory === 'All' ? '' : storedCategory}`);
        //   const data = await response.json();
        //   return data;
        // }

    } catch (error) {
        console.error("Error fetching unique Length:", error);
        return [];
    }
};


const fetchUniqueThickness = async (categoryName) => {

    // const jwtToken = sessionStorage.getItem('jwt');
    const jwtToken = localStorage.getItem('jwt');
    const headers = {
        'Content-Type': 'application/json',
    };
    if (jwtToken) {
        headers.Authorization = `Bearer ${jwtToken}`;
    } else {
        console.warn('No JWT token found in local storage. Sending request without Authorization header.');
    }
    try {
        // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/`);
        // if (storedCategory === null || storedCategory === 'All' || storedCategory === '') {
        // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/`);

        const apiUrl = categoryName === 'all' || categoryName === null || categoryName === '' || categoryName === undefined
            // ? 'http://127.0.0.1:8000/api/products/unique_width/'
            // : `http://127.0.0.1:8000/api/products/unique_width/?category=${categoryName}`;
            ? `${process.env.REACT_APP_MAP_API}/api/products/unique_thickness_and_count/`
            : `${process.env.REACT_APP_MAP_API}/api/products/unique_thickness_and_count/?category=${categoryName}`;

        const response = await fetch(apiUrl,
            {
                method: 'GET',
                headers,
            });

        // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/?category=${name === 'All' ? '' : name}`);
        const data = await response.json();
        return data;
        // } else {
        //   const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/?category=${storedCategory === 'All' ? '' : storedCategory}`);
        //   const data = await response.json();
        //   return data;
        // }

    } catch (error) {
        console.error("Error fetching unique Length:", error);
        return [];
    }
};


// };


// const CATEGORY_OPTIONS = [
//   'Apple iPhone',
//   'Samsung Galaxy',
//   'Nike Air Max',
//   'Adidas Ultraboost',
//   'Sony PlayStation',
// ];
// const CATEGORY_OPTIONS = [];




// const SHIPPING_OPTIONS = ['Fast', 'Saving', 'Free'];

// const TAG_OPTIONS = ['Books and Media', 'Pet', 'Electronics', 'Food', 'Automotive and Industrial'];

// ----------------------------------------------------------------------

// const defaultValues = {
//
//     // filterBrand: [BRAND_OPTIONS[1]],
//     filterBrand: [],
//     filterWidth: [],
//     filterLength: [],
//     filterThickness: [],
//     filterCategories: '',
//     // filterRating: null,
//     // filterStock: false,
//     // filterShipping: [],
//     // filterTag: [],
//     // filterPrice: {
//     //   start: 0,
//     //   end: 0,
//     // },
// };



function Block({ title, children, ...other }) {
  const [checked, setChecked] = useState(true);

  const handleOpen = () => {
    setChecked((prev) => !prev);
  };

  return (
      <Stack alignItems="flex-start" sx={{ width: 1 }} {...other}>
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={handleOpen}
            sx={{ width: 1, cursor: 'pointer' }}
        >
          <Typography variant="h6">{title}</Typography>

          <Iconify
              icon={checked ? 'carbon:subtract' : 'carbon:add'}
              sx={{ color: 'text.secondary' }}
          />
        </Stack>

        <Collapse unmountOnExit in={checked} sx={{ px: 0.5 }}>
          {children}
        </Collapse>
      </Stack>
  );
}

Block.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};


function BlockClosed({ title, children, ...other }) {
    const [checked, setChecked] = useState(false);

    const handleOpen = () => {
        setChecked((prev) => !prev);
    };

    return (
        <Stack alignItems="flex-start" sx={{ width: 1 }} {...other}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                onClick={handleOpen}
                sx={{ width: 1, cursor: 'pointer' }}
            >
                <Typography variant="h6">{title}</Typography>

                <Iconify
                    icon={checked ? 'carbon:subtract' : 'carbon:add'}
                    sx={{ color: 'text.secondary' }}
                />
            </Stack>

            <Collapse unmountOnExit in={checked} sx={{ px: 0.5 }}>
                {children}
            </Collapse>
        </Stack>
    );
}

BlockClosed.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
};

export default function
    EcommerceFilters({ mobileOpen, filterCategoryMain, onMobileClose, onFilterBrand, onFilterCategory, onFilterWidth, onFilterLength, onFilterThickness, onSearch}) {


    const defaultValues = {

    // filterBrand: [BRAND_OPTIONS[1]],
    filterBrand: [],
    filterWidth: [],
    filterLength: [],
    filterThickness: [],
    filterCategories: filterCategoryMain,
    // filterRating: null,
    // filterStock: false,
    // filterShipping: [],
    // filterTag: [],
    // filterPrice: {
    //   start: 0,
    //   end: 0,
    // },
};


    const [lang, setLang] = useState(i18n.language);

    const uniqueKey = uuidv4();
    const [brands, setBrandsOptions] = useState([]);
    // const [widths, setWidthsOptions] = useState([]);
    // const [category, setCategoryOptions] = useState([]);
    const [categoryCount, setCategoryOptionsCount] = useState([]);
    const [brandCount, setBrandsOptionsCount] = useState([]);
    const [widthCount, setWidthOptionsCount] = useState([]);
    const [lengthCount, setLengthOptionsCount] = useState([]);
    const [thicknessCount, setThicknessOptionsCount] = useState([]);

    const [searchText, setSearchText] = useState('');



    const fetchAndSetCategories = async () => {
      // const uniqueCategories = await fetchUniqueCategory();
      const uniqueCategoriesCount = await fetchUniqueCategoryCount();
      // console.log("Setting Categories Filter:", uniqueCategories); // Debug the categories before setting
      // console.log("Setting Categories Count:", uniqueCategoriesCount); // Debug the categories before setting
      // setCategoryOptions(uniqueCategories);
      setCategoryOptionsCount(uniqueCategoriesCount);
      // Store the value in sessionStorage
    }

    const fetchAndSetBrands = async (categoryName) => {
      const uniqueBrands = await fetchUniqueBrands(categoryName, );
      // console.log("Setting Brands Filter:", uniqueBrands); // Debug the brands before setting
      setBrandsOptions(uniqueBrands);
      setBrandsOptionsCount(uniqueBrands)
    };

    const fetchAndSetWidth = async (categoryName) => {
      const uniqueWidth = await fetchUniqueWidth(categoryName);
      // console.log("Setting Width Filter:", uniqueWidth); // Debug the brands before setting
      // setWidthsOptions(uniqueWidth);
      setWidthOptionsCount(uniqueWidth);
    };

    const fetchAndSetLength = async (categoryName) => {
        const uniqueLength = await fetchUniqueLength(categoryName);
        // console.log("Setting Length Filter:", uniqueLength); // Debug the brands before setting
        // setWidthsOptions(uniqueWidth);
        setLengthOptionsCount(uniqueLength);
    };

    const fetchAndSetThickness = async (categoryName) => {
        const uniqueThickness = await fetchUniqueThickness(categoryName);
        // console.log("Setting Thickness Filter:", uniqueThickness); // Debug the brands before setting
        // setWidthsOptions(uniqueWidth);
        setThicknessOptionsCount(uniqueThickness);
    };


    const isMdUp = useResponsive('up', 'md');
    const [filters, setFilters] = useState(defaultValues);

    const getSelected = (selectedItems, item) =>
        selectedItems.includes(item)
            ? selectedItems.filter((value) => value !== item)
            : [...selectedItems, item];


    useEffect(() => {

        // Event handler for when the language changes
        const handleLanguageChange = (lng) => setLang(lng);

        // Add event listener for language change
        i18n.on('languageChanged', handleLanguageChange);

        // console.log("EcommerceFilters: useEffect: filterCategoryMain:", filterCategoryMain);



        fetchAndSetCategories();
        // fetchAndSetBrands();
        // fetchAndSetWidth();
        // fetchAndSetLength();
        // fetchAndSetThickness();


        // evry time the filterCategoryMain changes, fetch the brands, width, length and thickness
        fetchAndSetBrands(filterCategoryMain);
        fetchAndSetWidth(filterCategoryMain);
        fetchAndSetLength(filterCategoryMain);
        fetchAndSetThickness(filterCategoryMain);


        // Cleanup function (will be called when the component is unmounted)
        return () => {
            // Remove event listener for language change
            i18n.off('languageChanged');
        };

    }, [filterCategoryMain]);
    // }, []);


    const handleChangeCategories = (categoryName) => {
        // console.log("EcommerceFilters: handleChangeCategories: categoryName:", categoryName);

        setFilters({
            ...filters,
            filterBrand: [],
            filterWidth: [],
            filterLength: [],
            filterThickness: [],
            filterCategories: categoryName,
        });

        // onFilterCategory(categoryName)
        // console.log("Handle change Category:", categoryName);
        fetchAndSetBrands(categoryName);
        fetchAndSetWidth(categoryName);
        fetchAndSetLength(categoryName);
        fetchAndSetThickness(categoryName);
    };


    const handleClearAll = () => {
        // setFilters(defaultValues);

        setFilters({
            ...filters,
            filterBrand: [],
            filterWidth: [],
            filterLength: [],
            filterThickness: [],
            // filterCategories: 'All',
            // on clear all set the category to the one selected in the main page
            filterCategories: sessionStorage.getItem('newCategory'),
        });

        // sessionStorage.setItem('category', filters.filterCategories);

        // fetchUniqueBrands('all');
        // fetchAndSetBrands('');
        fetchAndSetBrands(sessionStorage.getItem('newCategory'));

        // fetchUniqueWidth('all');
        // fetchAndSetWidth('');
        fetchAndSetWidth(sessionStorage.getItem('newCategory'));

        // fetchUniqueLength('all');
        // fetchAndSetLength('');
        fetchAndSetLength(sessionStorage.getItem('newCategory'));

        // fetchUniqueThickness('all');
        // fetchAndSetThickness('');
        fetchAndSetThickness(sessionStorage.getItem('newCategory'));

        onSearch('');
        setSearchText('');

    };


    const backHandleClearAll = () => {
        // setFilters(defaultValues);

        setFilters({
            ...filters,
            filterBrand: [],
            filterWidth: [],
            filterLength: [],
            filterThickness: [],
            filterCategories: 'All',
            // on clear all set the category to the one selected in the main page
            // filterCategories: sessionStorage.getItem('newCategory'),
        });

        // sessionStorage.setItem('category', filters.filterCategories);

        fetchUniqueBrands('all');
        // fetchAndSetBrands('');

        fetchUniqueWidth('all');
        // fetchAndSetWidth('');

        fetchUniqueLength('all');
        // fetchAndSetLength('');

        fetchUniqueThickness('all');
        // fetchAndSetThickness('');

        onSearch('');
        setSearchText('');

    };


    const handleChangeBrand = (name) => {
        setFilters({
          ...filters,
          filterBrand: getSelected(filters.filterBrand, name),
        });
    };

    const handleChangeWidth = (name) => {
        setFilters({
            ...filters,
            filterWidth: getSelected(filters.filterWidth, name),
        });
    };

    const handleChangeLength = (name) => {
        setFilters({
            ...filters,
            filterLength: getSelected(filters.filterLength, name),
        });
    };

    const handleChangeThickness = (name) => {
        setFilters({
            ...filters,
            filterThickness: getSelected(filters.filterThickness, name),
        });
    }

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
        if (onSearch) {
            onSearch(event.target.value);
            // console.log("Search Text:", event.target.value);
        }
    };


    onFilterCategory(filters.filterCategories)
    // console.log("-----------Categories Changed:");
    // console.log("Category:", filters.filterCategories);
    onFilterBrand(filters.filterBrand)
    // console.log("-----------Brands Changed:");
    // console.log("Brand:", filters.filterBrand);
    onFilterWidth(filters.filterWidth)
    // console.log("-----------Width Changed:");
    // console.log("Width:", filters.filterWidth);
    onFilterLength(filters.filterLength)
    // console.log("-----------Length Changed:");
    // console.log("Length:", filters.filterLength);
    onFilterThickness(filters.filterThickness)
    // console.log("-----------Thickness Changed:");
    // console.log("Thickness:", filters.filterThickness);



  const renderContent = (
      <>

          <Button
              fullWidth
              color="error"
              size="large"
              variant="contained"
              // startIcon={<Iconify icon="carbon:trash-can" />}
              startIcon ={<RotateLeftOutlinedIcon />}
              onClick={backHandleClearAll}
              style={{lineHeight:'1'}}
          >
              {i18n.t("backToMainCategories")}
          </Button>

    <Stack
        spacing={1}
        alignItems="flex-start"
        sx={{
            flexShrink: 0,
            width: { xs: 1, md: NAV.W_DRAWER },
            // bgcolor: "#f5f5f5", // replace 'green' with your desired color
            borderRadius: "16px", // add border radius
            p: 0,
        }}
    >

    {/*####################################################################################### ok #################*/}



        {/*<Label color='success' sx={{ width: 1, p:3, fontSize: 18 }}><TuneOutlinedIcon /> &nbsp;{i18n.t("filters")}</Label>*/}

        {/*<Label sx={{ width: 1, p:3, fontSize: 18 }}><TuneOutlinedIcon /> &nbsp;{i18n.t("filters")}</Label>*/}
        {isMdUp
            ? <Typography variant='h3' sx={{ width: 1, mt:0}}><TuneOutlinedIcon /> &nbsp;{i18n.t("filters")}</Typography >
            : <Typography variant='h3' sx={{ width: 1, mt:3}}><TuneOutlinedIcon /> &nbsp;{i18n.t("filters")}</Typography >
        }


        <Button
            fullWidth
            color="inherit"
            size="medium"
            variant="contained"
            startIcon={<Iconify icon="carbon:trash-can" />}
            // startIcon ={<RotateLeftOutlinedIcon />}
            onClick={handleClearAll}
        >
            {i18n.t("clearAllFilters")}
        </Button>


      {/* <Button */}
      {/*    fullWidth */}
      {/*    color="inherit" */}
      {/*    size="large" */}
      {/*    variant="contained" */}
      {/*    startIcon={<Iconify icon="carbon:trash-can" />} */}
      {/*    onClick={handleClearAll} */}
      {/* > */}
      {/*  Clear All Filters */}
      {/* </Button> */}

        <TextField
            label={
                <>
                    <SearchOutlinedIcon /> &nbsp;{i18n.t("search")}
                </>
            }
            sx={{ width: '100%',
                // padding: '14px',
                borderRadius: '8px',
                bgcolor: '#ffffff',
            }}
            variant="outlined"
            value={searchText}
            onChange={handleSearchChange}
        />

      {/* Category filter block */}

      <Block title={
          <>
            <CategoryIcon /> &nbsp;{i18n.t("categories")}
          </>
      }
             sx={{ width: '100%',
                   padding: '14px',
                   borderRadius: '8px',
                   bgcolor: '#F3FEF5',
                  // border: '1px solid #000', // optional border style
                }}
      >

          <EcommerceFilterCategory key={uniqueKey}
            // filterCategories={filters.filterCategories} // original 12/7/2021
            onChangeCategories={handleChangeCategories}
            // options={CATEGORY_OPTIONS}
            // options={category}
            optionsCount={categoryCount}
            sx={{mt: 1, }}

          />
      </Block>

        {/* Brand filter block */}
        <BlockClosed
            title={
                <>
                    <AssistantOutlinedIcon /> &nbsp;{i18n.t("brands")}
                </>
            }
            sx={{ width: '100%',
                // padding: '16px',
                padding: '10px 16px 10px 16px',
                borderRadius: '8px',
                bgcolor: '#F3FEF5',
                // border: '1px solid #000', // optional border style
            }}>

            <EcommerceFilterBrand key={uniqueKey}
                                  filterBrand={filters.filterBrand}
                                  onChangeBrand={handleChangeBrand}
                // options={BRAND_OPTIONS}
                                  options={brands}
                                  optionsCount={brandCount}
                                  sx={{ mt: 1 }}
            />
        </BlockClosed>

        {/* Width filter block */}
        <BlockClosed
            title={
                <>
                    <SyncAltOutlinedIcon /> &nbsp;{i18n.t("width")}
                </>
            }
            sx={{ width: '100%',
            // padding: '16px',
            padding: '10px 16px 10px 16px',
            borderRadius: '8px',
            bgcolor: '#F3FEF5',
            // border: '1px solid #000', // optional border style
        }}>

             <EcommerceFilterWidth key={uniqueKey}
                                  filterWidth={filters.filterWidth}
                                  onChangeWidth={handleChangeWidth}
                // options={BRAND_OPTIONS}
                //                   options={widths}
                                  optionsCount={widthCount}
                                  sx={{ mt: 1 }}
             />
        </BlockClosed>


        {/* Length filter block */}
        <BlockClosed
            title={
                <>
                    <SwapVertOutlinedIcon /> &nbsp;{i18n.t("length")}
                </>
            }
            sx={{ width: '100%',
            // padding: '16px',
            padding: '10px 16px 10px 16px',
            borderRadius: '8px',
            bgcolor: '#F3FEF5',
            // border: '1px solid #000', // optional border style
        }}>

            <EcommerceFilterLength key={uniqueKey}
                                  filterLength={filters.filterLength}
                                  onChangeLength={handleChangeLength}
                // options={BRAND_OPTIONS}
                //                   options={widths}
                                  optionsCount={lengthCount}
                                  sx={{ mt: 1 }}
            />
        </BlockClosed>


        {/* Thickness filter block */}
        <BlockClosed
            title={
                <>
                    <VerticalAlignCenterOutlinedIcon /> &nbsp;{i18n.t("thickness")}
                </>
            }
            sx={{ width: '100%',
            // padding: '16px',
            padding: '10px 16px 10px 16px',
            borderRadius: '8px',
            bgcolor: '#F3FEF5',
            // border: '1px solid #000', // optional border style
        }}>

            <EcommerceFilterThickness key={uniqueKey}
                                   filterThickness={filters.filterThickness}
                                   onChangeThickness={handleChangeThickness}
                // options={BRAND_OPTIONS}
                //                   options={widths}
                                   optionsCount={thicknessCount}
                                   sx={{ mt: 1 }}
            />
        </BlockClosed>

      {/*####################################################################################### ok #################*/}


      {/*<Button*/}
      {/*    fullWidth*/}
      {/*    color="inherit"*/}
      {/*    size="large"*/}
      {/*    variant="contained"*/}
      {/*    // startIcon={<Iconify icon="carbon:trash-can" />}*/}
      {/*    startIcon ={<RotateLeftOutlinedIcon />}*/}
      {/*    onClick={handleClearAll}*/}
      {/*>*/}
      {/*  {i18n.t("clearAllFilters")}*/}
      {/*</Button>*/}

      {/*
      <Block title="Price">
        <EcommerceFilterPrice
          filterPrice={filters.filterPrice}
          onChangeStartPrice={handleChangeStartPrice}
          onChangeEndPrice={handleChangeEndPrice}
          sx={{ mt: 2 }}
        />
      </Block>
      */}

      {/*
      <Block title="Shipping">
        <EcommerceFilterShipping
          filterShipping={filters.filterShipping}
          onChangeShipping={handleChangeShipping}
          options={SHIPPING_OPTIONS}
          sx={{ mt: 1 }}
        />
      </Block>
      */}
      {/*
      <Block title="Ratings">
        <EcommerceFilterRating
          filterRating={filters.filterRating}
          onChangeRating={handleChangeRating}
          sx={{ mt: 2 }}
        />
      </Block>
      */}

      {/* <EcommerceFilterStock filterStock={filters.filterStock} onChangeStock={handleChangeStock} /> */}

      {/*
      <Block title="Tags">
        <EcommerceFilterTag
          filterTag={filters.filterTag}
          onChangeTag={handleChangeTag}
          options={TAG_OPTIONS}
          sx={{ mt: 2 }}
        />
      </Block>
      */}


    </Stack>
      </>
  );

  return (
    <>
      {isMdUp ? (
        renderContent
      ) : (
        <Drawer
          anchor="right"
          open={mobileOpen}
          onClose={onMobileClose}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              pt: 3,
              px: 3,
              width: NAV.W_DRAWER,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}

EcommerceFilters.propTypes = {
    mobileOpen: PropTypes.bool,
    filterCategoryMain: PropTypes.string,
    onMobileClose: PropTypes.func,
    // onFilterBrand: PropTypes.arrayOf(PropTypes.string),
    onFilterBrand: PropTypes.func,
    onFilterWidth: PropTypes.func,
    // onFilterCategory: PropTypes.string,
    onFilterCategory: PropTypes.func,
    onFilterLength: PropTypes.func,
    onFilterThickness: PropTypes.func,
    onSearch: PropTypes.func,
};

// ----------------------------------------------------------------------
