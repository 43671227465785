import { useState, useEffect, useCallback } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from "react-i18next";
import {Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// @mui
import {Button, Container, Divider, Link, Unstable_Grid2 as Grid} from '@mui/material';
// _mock
import { _products } from 'src/_mock';
// components
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import LoadingScreen from 'src/components/loading-screen';
//
import ReviewEcommerce from '../../review/e-commerce';
import { EcommerceHeader } from '../layout';
import {
  EcommerceProductDetailsInfo,
  EcommerceProductDetailsCarousel,
  EcommerceProductDetailsDescription,
} from '../product/details';
import {price} from "../../../_mock/assets";
import {paths} from "../../../routes/paths";
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

 const _mockProduct = _products[0];
// const _mockProduct = JSON.parse(sessionStorage.getItem('product'));

export default function EcommerceProductView() {
    const navigate = useNavigate();
    const location = useLocation();

    const [lang, setLang] = useState(i18n.language);

    // ID-HT
    // console.log("location.search: ",new URLSearchParams(location.search).get('id_ht'))
    // const id_ht_pass = new URLSearchParams(location.search).get('id_ht') || 'No ID-HT provided';
    // const [loading, setLoading] = useState(true);
    // console.log("id_ht:", id_ht_pass);

    // ID
    // console.log("location.search: ",new URLSearchParams(location.search).get('id'))
    const id_pass = new URLSearchParams(location.search).get('id') || null;
    const [loading, setLoading] = useState(true);
    // console.log("id:", id_pass);



    // const [product, setProduct] = useState({});
    const [product, setProduct] = useState(localStorage.getItem('product') ? JSON.parse(localStorage.getItem('product')) : {});

    const fetchProducts = useCallback(async () => {

        // const jwtToken = sessionStorage.getItem('jwt');
        const jwtToken = localStorage.getItem('jwt');

        const headers = {
            'Content-Type': 'application/json',
        };

        if (jwtToken) {
            headers.Authorization = `Bearer ${jwtToken}`;
        } else {
            console.warn('No JWT token found in local storage. Sending request without Authorization header.');
        }

        try {
            // const response = await fetch(`http://127.0.0.1:8000/api/products/?fields=id,id_ht,category,brand,images,description?page=${page}`);
            // const response = await fetch(`http://127.0.0.1:8000/api/products/?fields=id,id_ht,category,brand,images,description&page=${page}&category=${filterCategory === 'All' ? '' : filterCategory}&brand=${filterBrand}`);
            // const response = await fetch(`http://127.0.0.1:8000/api/products/${id_ht_pass}/`);
            const response = await fetch(`${process.env.REACT_APP_MAP_API}/api/products/?id=${id_pass}`,
                {
                    method: 'GET',
                    headers,
                });
            // const response = await fetch(`http://127.0.0.1:8000/api/products/?page=${page}`);
            const data = await response.json();
            const dataResults = data.results[0];
            // console.log("getProducts:", dataResults);
            sessionStorage.setItem('product', JSON.stringify(dataResults));
            localStorage.setItem('product', JSON.stringify(dataResults));
            // sessionStorage.setItem('products', JSON.parse(data));
            // setProducts(data);
            // setProducts(data.results);
            // setProductsCount(data.count);
            // console.log("getPage:", page);
            setProduct(dataResults)
            return dataResults;

        } catch (error) {
            console.error("Error fetching Products:", error);
            return [];
        }
    // },[id_ht_pass]);
    },[id_pass]);



    const id_ht = `HT-id: ${product.id_ht}`;
    useEffect(() => {

        document.title = `${product.original_code? product.original_code : id_ht}`;


        // Event handler for when the language changes
        const handleLanguageChange = (lng) => setLang(lng);

        // Add event listener for language change
        i18n.on('languageChanged', handleLanguageChange);

        const fakeLoading = async () => {
            await new Promise((resolve) => setTimeout(resolve, 500));
            setLoading(false);
        };
        fakeLoading();
        fetchProducts();


        // Cleanup function (will be called when the component is unmounted)
        return () => {
            // Remove event listener for language change
            i18n.off('languageChanged', handleLanguageChange);
        };

    }, [fetchProducts, id_ht, product.original_code]);

    if (loading) {
        return <LoadingScreen />;
    }

    // console.log("product: ", product);

    // const onDownload = () => {
    //     const link = document.createElement("a");
    //     link.download = `${product.id_ht}.jpg`;
    //     link.href = `${product.images[0].image}`;
    //     link.click();
    //
    // };

    // const onDownload = ()=>{
    //     let url = `${product.images[0].image}`;
    //     saveAs(url, `${product.id_ht}.jpg`);
    // }

    const codes = [
        product.original_code,
        product.original_code_2,
        product.original_code_3,
        product.original_code_4,
        product.original_code_5
    ].filter(Boolean);  // Removes any undefined or falsy values


    const specifications = [];


    if(product.holes_type !== undefined){
        specifications.splice(8, 0, { label: 'Holes Type:', value: product.holes_type },);
    }
    if(product.holes_number !== undefined){
        specifications.splice(8, 0, { label: 'Holes Number:', value: product.holes_number },);
    }
    if(product.holes_diameter_mm !== undefined){
        specifications.splice(8, 0, { label: 'Holes Diameter:', value: `${product.holes_diameter_mm} mm` });
    }
    if(product.holes_spacing !== undefined){
        specifications.splice(8, 0, { label: 'Holes Spacing:', value: `${product.holes_spacing} mm` },);
    }


    if(product.thickness !== undefined){
        specifications.splice(0, 0, { label: 'Thickness:', value: `${product.thickness} mm` },);
    }
    if(product.weight !== undefined){
        specifications.splice(0, 0, { label: 'Weight:', value: `${product.weight} Kg` },);
    }
    if(product.length !== undefined){
        specifications.splice(0, 0, { label: 'Length:', value: `${product.length} mm` },);
    }
    if(product.width !== undefined){
        specifications.splice(0, 0, { label: 'Width:', value: `${product.width} mm` },);
    }
    if(product.family !== undefined){
        specifications.splice(0, 0, { label: 'Family:', value: product.family },);
    }
    if(product.brand !== undefined){
        specifications.splice(0, 0, { label: 'Brand:', value: product.brand },);
    }

    if(product.id_ht !== undefined){
        specifications.splice(0, 0, { label: 'HT-Code:', value: product.id_ht },);
    }
    // if(product.original_code !== undefined){
    //     specifications.splice(2, 0, { label: 'Original Code:', value: product.original_code },);
    // }

    if (codes.length) {
        const combinedValue = codes.join(' ');
        specifications.splice(2, 0, { label: 'Original Code:', value: combinedValue });
    }

    console.log("prodprod: ", product);

  return (
    <>
      {/* <EcommerceHeader /> */}



      <Container sx={{ overflow: 'hidden', mt: 4, mb: 4}}>
        {/* <CustomBreadcrumbs */}
        {/*  links={[ */}
        {/*    { */}
        {/*      name: 'Home', */}
        {/*    }, */}
        {/*    { */}
        {/*      name: 'Mobile Phones', */}
        {/*    }, */}
        {/*    { */}
        {/*      name: 'Apple iPhone 14', */}
        {/*    }, */}
        {/*  ]} */}
        {/*  sx={{ my: 5 }} */}
        {/* /> */}


          {/*<Button*/}
          {/*    // component={Link}*/}
          {/*    // to='/'*/}
          {/*    // fullWidth={!isMdUp}*/}
          {/*    onClick={() => navigate(-1)}*/}
          {/*    color="success"*/}
          {/*    variant="contained"*/}
          {/*    startIcon={<Iconify icon="carbon:arrow-left" />}*/}
          {/*>*/}
          {/*    {i18n.t("backToProducts")}*/}
          {/*</Button>*/}


        <Grid container spacing={{ xs: 5, md: 8 }}>
          <Grid xs={12} md={12} lg={7}>
             <EcommerceProductDetailsCarousel images={product.images} />
          </Grid>
            <Grid container columnSpacing={{ md: 8 }}>
                <Grid xs={12} md={12} lg={12} >
                    <EcommerceProductDetailsDescription
                        specifications={specifications}
                    />
                </Grid>
            </Grid>
        </Grid>



          <Grid xs={12} md={6} lg={5}>
              {/* <Button onClick={onDownload} variant="contained" color="primary"> */}
              {/*    Download */}
              {/* </Button> */}
              <EcommerceProductDetailsInfo
                  name={product.id_ht}
                  product={product}
                  // price={_mockProduct.price}
                  // rating={_mockProduct.rating}
                  // review={_mockProduct.review}
                  // priceSale={_mockProduct.priceSale}
                  caption={product.description}
                  download={product.images[0].image ? product.images[0].image : "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png"}
              />
          </Grid>

      </Container>

      {/* <ReviewEcommerce /> */}
    </>
  );
}
