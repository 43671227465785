import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { Stack, Link, IconButton, InputAdornment } from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Label from "../../../components/label";

// ----------------------------------------------------------------------

export default function AuthLoginForm({ onFormSubmit, loginFailed}) {


  // console.log('AuthLoginForm.loginFailed=', loginFailed);
  const [showPassword, setShowPassword] = useState(false);
  const [lang, setLang] = useState(i18n.language);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('That is not an email'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password should be of minimum 6 characters length'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      // console.log('DATA', data);

      if (onFormSubmit) {
        onFormSubmit(data.email, data.password);
      }


    } catch (error) {
      console.error(error);
    }

  };

  AuthLoginForm.propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    loginFailed: PropTypes.array,
  };


  useEffect(() => {

    // Event handler for when the language changes
    const handleLanguageChange = (lng) => setLang(lng);

    // Add event listener for language change
    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup function (will be called when the component is unmounted)
    return () => {
      // Remove event listener for language change
      i18n.off('languageChanged', handleLanguageChange);
    };

  }, );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5} alignItems="flex-end">
        <RHFTextField name="email" label={i18n.t("email_address")} />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Iconify icon={showPassword ? 'carbon:view' : 'carbon:view-off'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Link
          // component={RouterLink}
          // to={paths.resetPassword}
            href="https://htsrl.it/contatti/"
          variant="body2"
          underline="always"
          color="text.secondary"
        >
          {i18n.t("forgot_password")} {i18n.t("contact_us")}
        </Link>

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {i18n.t("login")}
        </LoadingButton>
        {loginFailed[0] &&
            <Label color="error" sx={{ width: 1, p: 3 }}>
              {loginFailed[1]}
            </Label>
        }
      </Stack>
    </FormProvider>
  );
}
