// @mui
import PropTypes from 'prop-types';
import { Checkbox, Stack, FormControlLabel, Typography } from '@mui/material';


// ----------------------------------------------------------------------

export default function EcommerceFilterThickness({
                                                  // options,
                                                  optionsCount,
                                                  filterThickness,
                                                  onChangeThickness,
                                                  ...other
                                              }) {

    // console.log('optionsCount', optionsCount);

    return (
        <Stack {...other}>
            {/* {options.map((option) => ( */}
            {optionsCount.map((option) => (
                <FormControlLabel
                    key={option.thickness}
                    control={
                        <Checkbox
                            size="small"
                            value={option.thickness}
                            checked={filterThickness.includes(option.thickness)}
                            onChange={() => onChangeThickness(option.thickness)}
                        />
                    }
                    // label={`${option} mm`}
                    // label={`${option.width} mm (${option.total_products})`}
                    label={

                        <Typography
                            component="div"
                            sx={{
                                fontSize: 13,
                                ...(filterThickness.includes(option.thickness) && {
                                    fontWeight: 700,
                                }),
                            }}
                        >
                            {option.thickness} mm<Typography component="span" sx={{fontStyle: 'italic', fontSize: 9, ml: 0.1}}>({option.total_products})</Typography>
                        </Typography>

                    }
                />
            ))}
        </Stack>
    );
}

EcommerceFilterThickness.propTypes = {
    filterThickness: PropTypes.array,
    onChangeThickness: PropTypes.func,
    // options: PropTypes.array,
    optionsCount: PropTypes.array
};