import PropTypes from 'prop-types';
import { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';
// import ReactHtmlParser from 'react-html-parser';
// eslint-disable-next-line import/no-extraneous-dependencies
import {Trans, useTranslation} from "react-i18next";
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Button, Rating, Typography, TextField, Divider } from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// import { PDFDocument, rgb } from 'pdf-lib';
// routes
import { paths } from 'src/routes/paths';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
//
import { ProductColorPicker, ProductOptionPicker, ProductPrice } from '../../components';

// ----------------------------------------------------------------------

// const COLOR_OPTIONS = [
//   { label: '#FA541C', value: 'red' },
//   { label: '#754FFE', value: 'violet' },
//   { label: '#00B8D9', value: 'cyan' },
//   { label: '#36B37E', value: 'green' },
// ];
//
// const MEMORY_OPTIONS = [
//   { label: '128GB', value: '128gb' },
//   { label: '256GB', value: '256gb' },
//   { label: '512GB', value: '512gb' },
//   { label: '1TB', value: '1tb' },
// ];

// ----------------------------------------------------------------------

export default function EcommerceProductDetailsInfo({ name, product, price, rating, review, priceSale, caption, download, ...other}) {
    const isMdUp = useResponsive('up', 'md');
    const [downloading, setDownloading] = useState(false);


    // pdf download
    // const generatePdf = async (imageBlob, lines) => {
    //     const pdfDoc = await PDFDocument.create();
    //     const [imagePage] = await pdfDoc.addPages();
    //
    //     const imgUint8Array = new Uint8Array(await imageBlob.arrayBuffer());
    //     const img = await pdfDoc.embedJpg(imgUint8Array);
    //
    //     imagePage.drawImage(img, {
    //         x: imagePage.width / 2 - img.width / 2,
    //         y: imagePage.height - img.height,
    //         width: img.width,
    //         height: img.height,
    //     });
    //
    //     // Add overlay details
    //     let yPosition = imagePage.height - img.height - 10; // Adjust as needed
    //     const fontSize = 24;
    //     for (let line of lines) {
    //         imagePage.drawText(line, {
    //             x: 10,
    //             y: yPosition,
    //             size: fontSize,
    //             color: rgb(0, 0, 0),
    //         });
    //         yPosition -= (fontSize + 2); // Adjust spacing between lines as needed
    //     }
    //
    //     const pdfBytes = await pdfDoc.save();
    //     return new Blob([pdfBytes], { type: 'application/pdf' });
    // };
    //
    // const downloadPdf = async () => {
    //     const imageUrl = download;
    //     const filename = `${i18n.t("htCode")}_${product.id_ht}_(${product.brand}).pdf`;
    //     setDownloading(true);
    //
    //     const response = await fetch(imageUrl.replace('http://', 'https://'));
    //     const imageBlob = await response.blob();
    //
    //     const lines = [
    //         // ... same as before
    //     ].filter(Boolean);
    //
    //     const pdfBlob = await generatePdf(imageBlob, lines);
    //
    //     const objectUrl = URL.createObjectURL(pdfBlob);
    //
    //     const link = document.createElement('a');
    //     link.href = objectUrl;
    //     link.download = filename;
    //
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //
    //     URL.revokeObjectURL(objectUrl);
    //
    //     setDownloading(false);
    // };

    function getFamilyMessage(family) {
        switch (family) {
            case "COATED HTITE":
                return i18n.t("coatedHtitePresent");
            case "MIG":
                return i18n.t("migPresent");
            case "LASER CLADDING":
                return i18n.t("laserCladdingPresent");
            case "":
                return i18n.t("noFamilyPresent");
            default:
                return i18n.t("unknownFamily");

        }
    }



    // image download
    const addTextOverlay = async (imageBlob, text) =>
        new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();
            const padding = 10;

            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;

                // Draw the image on the canvas
                ctx.drawImage(img, 0, 0);


                // Configure text settings
                ctx.font = '22px Arial';
                ctx.fillStyle = 'black';
                ctx.textAlign = 'left';
                ctx.textBaseline = 'bottom';

                // Draw the text on the canvas
                // ctx.fillText(`${i18n.t("htCode")}: ${product.id_ht}`, padding, canvas.height - padding);
                // ctx.fillText(`${i18n.t("htCode")}: ${product.id_ht}\n${i18n.t("category")}: ${product.category}\n${i18n.t("brand")}: ${product.brand}\n${i18n.t("description")}: ${product.description}`, padding, canvas.height - padding);


                const originalCodes = [
                    product.original_code,
                    product.original_code_2,
                    product.original_code_3,
                    product.original_code_4,
                    product.original_code_5
                ].filter(Boolean).join(', ');

                const lines = [
                    `${i18n.t("originalCode")}: ${originalCodes}`,
                    // getFamilyMessage(product.family),
                    product.family ? `${i18n.t("family")}: ${i18n.t("expected")}` : null,
                    product.holes_type === "FORI" ? `${i18n.t("holesDiameter")}: ${product.holes_diameter_mm} mm` : null,
                    `${i18n.t("holesNumber")}: ${product.holes_number}`,
                    `${i18n.t("holesType")}: ${product.holes_type}`,
                    `${i18n.t("weight")}: ${product.weight} kg`,
                    `${i18n.t("thickness")}: ${product.thickness} mm`,
                    `${i18n.t("length")}: ${product.length} mm`,
                    `${i18n.t("width")}: ${product.width} mm`,
                    `${i18n.t("brand")}: ${product.brand}`,
                    `${i18n.t("category")}: ${product.category}`,
                    // `${i18n.t("originalCode")}: ${product.original_code}`,
                    `${i18n.t("htCode")}: ${product.id_ht}`,
                ].filter(Boolean); // remove null entries

                const lineHeight = 24; // adjust this value to your needs
                let i = 0;
                while (i < lines.length) {
                    ctx.fillText(lines[i], padding, canvas.height -(padding + (lineHeight * i)));
                    i += 1;
                }

                // Convert the canvas to a Blob
                canvas.toBlob((blob) => {
                    resolve(blob);
                });
            };

            img.src = URL.createObjectURL(imageBlob);
        });

    const downloadImage = async () => {
        const imageUrl = download;
        // const filename = `HT-Code_${name}.jpg`;
        const filename = `${i18n.t("htCode")}_${product.id_ht}_(${product.brand}).jpg`;
        setDownloading(true);


        const response = await fetch(imageUrl.replace('http://', 'https://'));
        const imageBlob = await response.blob();

        // Add the text overlay to the image
        const overlayBlob = await addTextOverlay(imageBlob, product.id_ht);

        // Create an Object URL from the Blob
        const objectUrl = URL.createObjectURL(overlayBlob);

        // Create an anchor element with a download attribute
        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = filename;

        // Trigger the download by clicking the anchor element
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the Object URL
        URL.revokeObjectURL(objectUrl);

        setDownloading(false);
    };


  return (
    <>

      {/* <Label color="success" sx={{ mb: 3 , mt: 5}}> */}
      {/*  In Stock */}
      {/* </Label> */}

       <Stack spacing={1} sx={{ mb: 2 }}>
        <Typography variant="h3">
          <Typography variat="h3" sx={{ color: 'text.disabled', display: 'inline-block'}}>
            {/*HT Code:&nbsp;&nbsp;*/}
              {i18n.t("htCode")}:&nbsp;&nbsp;
          </Typography>
          {name}
        </Typography>

        <Stack spacing={0.5} direction="row" alignItems="center">
          {/* <Rating size="small" value={rating} readOnly precision={0.5} /> */}

          {/* <Typography variant="caption" sx={{ color: 'text.disabled' }}> */}
          {/*  ({review} reviews) */}
          {/* </Typography> */}
        </Stack>
      </Stack>

       <Stack spacing={2}>
        {/* <ProductPrice price={price} priceSale={priceSale} sx={{ typography: 'h5' }} /> */}

          <Typography variat="h3" sx={{ color: 'text.disabled', typography: 'body1'}}>{i18n.t("description")}:</Typography>
           {/*<Typography variant="body1" sx={{ color: 'text.secondary', lineHeight: "1.7rem"}}>{i18n.t(caption)}</Typography>*/}
           <Typography variant="body1" sx={{ color: 'text.secondary', lineHeight: "1.7rem"}} dangerouslySetInnerHTML={{ __html: i18n.t(caption) }} />


           {/*<Typography variant="body1" sx={{ color: 'text.secondary', lineHeight: "1.7rem"}}>*/}
           {/*    {ReactHtmlParser(i18n.t(caption))}*/}
           {/*</Typography>*/}

       </Stack>

      {/* <Stack spacing={3} sx={{ my: 5 }}> */}
      {/*  <Stack spacing={2}> */}
      {/*    <Typography variant="subtitle2">Color</Typography> */}
      {/*    <ProductColorPicker value={color} onChange={handleChangeColor} options={COLOR_OPTIONS} /> */}
      {/*  </Stack> */}

        {/* <Stack spacing={2}> */}
          {/* <Typography variant="subtitle2">Memory</Typography> */}
          {/* <ProductOptionPicker */}
          {/*  value={memory} */}
          {/*  onChange={handleChangeMemory } */}
          {/*  options={MEMORY_OPTIONS} */}
          {/* /> */}
        {/* </Stack> */}
      {/* </Stack > */}

      <Stack spacing={2} direction={{ xs: 'column', md: 'row'}} alignItems={{ md: 'center' }}>
        {/* <TextField */}
        {/*  select */}
        {/*  hiddenLabel */}
        {/*  SelectProps={{ */}
        {/*    native: true, */}
        {/*  }} */}
        {/*  sx={{ */}
        {/*    minWidth: 100, */}
        {/*  }} */}
        {/* > */}
        {/*  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => ( */}
        {/*    <option key={option} value={option}> */}
        {/*      {option} */}
        {/*    </option> */}
        {/*  ))} */}
        {/* </TextField> */}

        <Stack direction="row" spacing={2} sx={{mt: 6}}>
          <Button
            // component={RouterLink}
            // to={paths.eCommerce.cart}
              onClick={downloadImage}
              disabled={downloading}
            fullWidth={!isMdUp}
            // href={download}
            size="large"
            color="inherit"
            variant="contained"
            startIcon={<SimCardDownloadIcon />}
          >
            Download
          </Button>
      {/*      <Label color="error">ERROR. (verify icon)</Label> */}
      {/*    /!* <Button *!/ */}
      {/*    /!*  component={RouterLink} *!/ */}
      {/*    /!*  to={paths.eCommerce.cart} *!/ */}
      {/*    /!*  fullWidth={!isMdUp} *!/ */}
      {/*    /!*  size="large" *!/ */}
      {/*    /!*  color="primary" *!/ */}
      {/*    /!*  variant="contained" *!/ */}
      {/*    /!* > *!/ */}
      {/*    /!*  Buy Now *!/ */}
      {/*    /!* </Button> *!/ */}
        </Stack>
       </Stack>

      <Divider sx={{ borderStyle: 'dashed', my: 3, mt:5, mb:5}} />

      {/* <Stack spacing={3} direction="row" justifyContent={{ xs: 'center', md: 'unset' }}> */}
      {/*  <Stack direction="row" alignItems="center" sx={{ typography: 'subtitle2' }}> */}
      {/*    <Iconify icon="carbon:add-alt" sx={{ mr: 1 }} /> Compare */}
      {/*  </Stack> */}

      {/*  <Stack direction="row" alignItems="center" sx={{ typography: 'subtitle2' }}> */}
      {/*    <Iconify icon="carbon:favorite" sx={{ mr: 1 }} /> Compare */}
      {/*  </Stack> */}

      {/*  <Stack direction="row" alignItems="center" sx={{ typography: 'subtitle2' }}> */}
      {/*    <Iconify icon="carbon:share" sx={{ mr: 1 }} /> Compare */}
      {/*  </Stack> */}
      {/* </Stack> */}
    </>
  );
}

EcommerceProductDetailsInfo.propTypes = {
  caption: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  priceSale: PropTypes.number,
  rating: PropTypes.number,
  review: PropTypes.number,
  download: PropTypes.string,
    product: PropTypes.object,


  // width: PropTypes.number,
  // thickness: PropTypes.number,
  // length: PropTypes.number,
  // weight: PropTypes.number,
  // holes_type: PropTypes.string,
  // holes_number: PropTypes.number,


  // holes_diameter_mm = PropTypes.number,
  // holes_sparing = models.FloatField(null=True, default=None)
  // original_code = models.CharField(max_length=255, null=True, blank=True)
  // original_code_2 = models.CharField(max_length=255, null=True, blank=True)
  // original_code_3 = models.CharField(max_length=255, null=True, blank=True)
  // original_code_4 = models.CharField(max_length=255, null=True, blank=True)
  // original_code_5 = models.CharField(max_length=255, null=True, blank=True)
  // family = models.CharField(max_length=255, null=True, blank=True)
  // criticality = models.IntegerField(default=0, null=False)
  // date_created = models.DateTimeField(default=timezone.now)
  // date_updated = models.DateTimeField(auto_now=True)
};
