// @mui
import PropTypes from 'prop-types';
import {Checkbox, Stack, FormControlLabel, Typography, Box} from '@mui/material';
import i18n from "i18next";
import React, {useEffect} from "react";

// ----------------------------------------------------------------------

export default function EcommerceFilterBrand({ options, optionsCount, filterBrand, onChangeBrand, ...other }) {

  useEffect(() => {
    // console.log("EcommerceFilterBrand optionsCount: ", optionsCount);
  }, [optionsCount, filterBrand]);


  return (
      <Stack {...other}>

        {options.map((option, index) => (
            <FormControlLabel
                key={index}
                control={
                  <Checkbox
                      size="small"
                      value={option.brand}
                      checked={filterBrand.includes(option.brand)}
                      onChange={() => onChangeBrand(option.brand)}
                  />
                }
                labelPlacement="end"
                label={
                  <Box display="flex" alignItems="center">

                    <Typography
                        sx={{
                          ...(filterBrand.includes(option.brand) && {
                            fontWeight: 700,
                          }),
                        }}
                    >

                    {
                      (() => {
                        switch (option.brand) {
                          case "Gregoire besson": return "Gregoire Besson";
                          case "Kverneland/maletti": return "Kverneland/Maletti";
                          case "Vogel&noot": return "Vogel&Noot";
                          default: return option.brand;
                        }
                      })()
                    }

                    </Typography>
                    <Typography sx={{fontStyle: 'italic', fontSize: 9, ml: 0.1}}>({option.total_products})</Typography>
                  </Box>
                }
            />
        ))}
      </Stack>
  );

  // return (
  //   <Stack {...other}>
  //
  //     {options.map((option, index) => (
  //       <FormControlLabel
  //         key={index}
  //         control={
  //           <Checkbox
  //             size="small"
  //             value={option.brand}
  //             checked={filterBrand.includes(option.brand)}
  //             onChange={() => onChangeBrand(option.brand)}
  //           />
  //         }
  //         // label={option === "Kverneland/maletti" ? "Kverneland/Maletti" : option}
  //           label =
  //         {
  //           (() => {
  //             switch (option.brand) {
  //               case "Gregoire besson": return "Gregoire Besson";
  //               case "Kverneland/maletti": return "Kverneland/Maletti";
  //               case "Vogel&noot": return "Vogel&Noot";
  //               default: return option.brand;
  //             }
  //           })()
  //         }
  //       />
  //
  //     ))}
  //
  //
  //
  //     {/*{options.map((option, index) => (*/}
  //     {/*  <FormControlLabel*/}
  //     {/*    key={index}*/}
  //     {/*    control={*/}
  //     {/*      <Checkbox*/}
  //     {/*        size="small"*/}
  //     {/*        value={option}*/}
  //     {/*        checked={filterBrand.includes(option)}*/}
  //     {/*        onChange={() => onChangeBrand(option)}*/}
  //     {/*      />*/}
  //     {/*    }*/}
  //     {/*    // label={option === "Kverneland/maletti" ? "Kverneland/Maletti" : option}*/}
  //     {/*      label =*/}
  //     {/*    {*/}
  //     {/*      (() => {*/}
  //     {/*        switch (option) {*/}
  //     {/*          case "Gregoire besson": return "Gregoire Besson";*/}
  //     {/*          case "Kverneland/maletti": return "Kverneland/Maletti";*/}
  //     {/*          case "Vogel&noot": return "Vogel&Noot";*/}
  //     {/*          default: return option;*/}
  //     {/*        }*/}
  //     {/*      })()*/}
  //     {/*    }*/}
  //     {/*  />*/}
  //     {/*))}*/}
  //
  //   </Stack>
  // );
}

EcommerceFilterBrand.propTypes = {
  filterBrand: PropTypes.array,
  onChangeBrand: PropTypes.func,
  options: PropTypes.array,
  optionsCount: PropTypes.array,
};
