import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';
import PropTypes from 'prop-types';
// @mui
import { Box, Stack, Pagination, Typography, Divider, Card, CardContent, CardMedia, Grid, Link} from '@mui/material';
//
import {
  EcommerceProductViewListItem,
  EcommerceProductViewGridItem,
  EcommerceProductViewListItemSkeleton,
  EcommerceProductViewGridItemSkeleton,
} from '../item';

import { _products0 } from "../../../../_mock/arrays/_products0";
// import * as events from "events";

// ----------------------------------------------------------------------



export default function EcommerceProductList({ getItems, loading, viewMode, products, productsCategories, category, onPage, onFilterCategoryMain }) {

    const [pageCount, setPageCount] = useState(0);
    const [paginationChange, setPaginationChange] = useState(1);
    const [lang, setLang] = useState(i18n.language);
    // const [productsCountB, setProductsCountB] = useState(0);
    // setProductsCountB(Math.ceil(getItems/12));
    // Get max page number
    // const PageCount = () => {

    // console.log("getItems_child: ", getItems);
    useEffect(() => {
        // Replace this with the correct endpoint for your API


        const fetchData = async () => {
            const endpoint = `${process.env.REACT_APP_MAP_API}/api/products/max_page_number/`;
            try {
                const response = await fetch(endpoint);
                const data = await response.json();
                // Assuming the API returns the count in a property called 'count'
                // console.log("Data Count: ", data.max_page_number)
                setPageCount(data.max_page_number);
            } catch (error) {
                console.error('Error fetching page count:', error);
            }
        };

        fetchData();



        // Event handler for when the language changes
        const handleLanguageChange = (lng) => setLang(lng);

        // Add event listener for language change
        i18n.on('languageChanged', handleLanguageChange);

        // Cleanup function (will be called when the component is unmounted)
        return () => {
            // Remove event listener for language change
            i18n.off('languageChanged', handleLanguageChange);
        };




    }, [paginationChange]);
    // console.log("Page Count: ",pageCount)

    // }

    const handleChangePagination = (event, page) => {
        setPaginationChange(page)
        onPage(page);
        // console.log("Pagination Change: ", page);
    }

    // PageCount();

    const renderProducts = () => {
        const elements_grid = (loading ? [...Array(9)] : products).map((product, index) =>
            product ? (
                <EcommerceProductViewGridItem key={product.id} product={product} />
            ) : (
                <EcommerceProductViewGridItemSkeleton key={index} />
            )
        );

        const elements_list = (loading ? [...Array(9)] : products).map((product, index) =>
            product ? (
                <EcommerceProductViewListItem key={product.id} product={product} />
            ) : (
                <EcommerceProductViewListItemSkeleton key={index} />
            )
        );

        if (viewMode === 'grid') {
            return (
                <Box
                    rowGap={4}
                    columnGap={3}
                    display="grid"
                    gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(3, 1fr)' }}
                >
                    {elements_grid}
                </Box>
            );
        }
            return (
                <Stack spacing={4}>
                    {elements_list}
                </Stack>
            );

    }

    // Get data from session storage
    const sessionData = sessionStorage.getItem('productsCategories');
    // Parse the JSON string to an object
    const data = sessionData ? JSON.parse(sessionData).filter(item => item.name !== "All") : [];
    // const [category, setCategory] = React.useState(null); // create a state variable to store clicked category


    const renderProductsCategories = () => (
        // <Typography variant="subtitle1" style={{ textAlign: 'center', color: 'grey'}}>
        //     renderProductsCategories
        // </Typography>

        <Grid container spacing={2}>
            {data.map((item) => (
                <Grid item xs={12} sm={viewMode === 'grid' ? 6 : 12} md={viewMode === 'grid' ? 4 : 12} key={item.id}>
                    <Box display="flex" flexDirection="column" height="100%">
                        <Card style={{flexGrow: 1, display: 'flex', flexDirection: viewMode === 'grid' ? 'column' : 'row', backgroundColor: '#f6f6f6'}}>
                            {item.image && (
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={item.image}
                                    alt={item.name}
                                    style={{width: viewMode === 'grid' ? '100%' : '30%', objectFit: 'cover'}}
                                />
                            )}
                            {/*<CardContent style={{flexGrow: 1, cursor: 'pointer'}}>*/}
                            {/*    <Typography variant="h5" component="div" onClick={() => onFilterCategoryMain(item.name)}>*/}
                            {/*<Box sx={{ flexGrow: 1, cursor: 'pointer', '&:hover': { backgroundColor: '#f3fef5' } }} component={CardContent} onClick={() => onFilterCategoryMain(item.name)}>*/}
                            {/*    <Typography variant="h5" component="div">*/}
                            {/*        /!*{item.name}*!/*/}

                            {/*        {*/}
                            {/*            (() => {*/}

                            {/*                switch (item.name) {*/}
                            {/*                    case "Coltelli trinciastocchi": return <>{i18n.t("coltelliTrinciastocchi")}</>;*/}
                            {/*                    case "Denti/coltelli per erpice": return <>{i18n.t("dentiColtelliErpice")}</>;*/}
                            {/*                    case "Dischi": return <>{i18n.t("dischi")}</>;*/}
                            {/*                    case "Lame carro unifeed": return <>{i18n.t("lameCarroUnifeed")}</>;*/}
                            {/*                    case "Lame per raccolta mais": return <>{i18n.t("lameRaccoltaMais")}</>;*/}
                            {/*                    case "Pale": return <>{i18n.t("pale")}</>;*/}
                            {/*                    case "Punte": return <>{i18n.t("punte")}</>;*/}
                            {/*                    case "Raschietti": return <>{i18n.t("raschietti")}</>;*/}
                            {/*                    case "Ripuntatori": return <>{i18n.t("ripuntatori")}</>;*/}
                            {/*                    case "Soc": return <>{i18n.t("soc")}</>;*/}
                            {/*                    case "All": return <>{i18n.t("all")}</>;*/}
                            {/*                    default: return item.name;*/}
                            {/*                }*/}
                            {/*            })()*/}
                            {/*        }*/}


                            {/*    </Typography>*/}
                            {/*    /!*<Typography variant="body2" color="text.secondary">*!/*/}
                            {/*    /!*    {`${i18n.t("totalProducts")}: ${item.total_products}`}*!/*/}
                            {/*    /!*</Typography>*!/*/}
                            {/*/!*</CardContent>*!/*/}
                            {/*</Box>*/}

                            <Box
                                sx={{
                                    textAlign: 'center',
                                    flexGrow: 1,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#f3fef5',
                                        '& .zoomable': {
                                            transform: 'scale(1.1)',
                                            transition: 'transform 0.3s ease',
                                            display: 'inline-block'
                                        }
                                    }
                                }}
                                component={CardContent}
                                onClick={() => onFilterCategoryMain(item.name)}
                            >
                                <Typography
                                    variant="h5"
                                    component="div"
                                    className="zoomable"
                                    sx={{
                                        transform: 'scale(1)',
                                        transition: 'transform 0.3s ease',
                                        display: 'inline-block'
                                    }}
                                >
                                    {
                                        (() => {
                                            switch (item.name) {
                                                case "Coltelli trinciastocchi": return <>{i18n.t("coltelliTrinciastocchi")}</>;
                                                case "Denti/coltelli per erpice": return <>{i18n.t("dentiColtelliErpice")}</>;
                                                case "Dischi": return <>{i18n.t("dischi")}</>;
                                                case "Lame carro unifeed": return <>{i18n.t("lameCarroUnifeed")}</>;
                                                case "Lame per raccolta mais": return <>{i18n.t("lameRaccoltaMais")}</>;
                                                case "Pale": return <>{i18n.t("pale")}</>;
                                                case "Punte": return <>{i18n.t("punte")}</>;
                                                case "Raschietti": return <>{i18n.t("raschietti")}</>;
                                                case "Ripuntatori": return <>{i18n.t("ripuntatori")}</>;
                                                case "Zappe per fresatrici": return <>{i18n.t("zappePerFresatrici")}</>;
                                                case "Soc": return <>{i18n.t("soc")}</>;
                                                case "All": return <>{i18n.t("all")}</>;
                                                default: return item.name;
                                            }
                                        })()
                                    }
                                </Typography>
                            </Box>


                        </Card>
                    </Box>
                </Grid>
            ))}
        </Grid>

    );

    const renderProductsNone = () => (
    // <Typography variant="subtitle1" style={{ textAlign: 'center', color: 'grey'}}>
    //     !!! renderProductsNone !!!
    // </Typography>

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box display="flex" flexDirection="column" height="100%">
                    <Card style={{flexGrow: 1, backgroundColor: '#f2f2f2'}}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                No products found
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Please try again later or <Link href="mailto:info@htsrl.it">contact Support</Link>.
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </Grid>


    );


    return (
    <>
        {/*{(Math.ceil(getItems/16) > 1 ? (*/}
        {/*    <>*/}
        {/*        <Typography variant="subtitle1" style={{ textAlign: 'center', color: 'grey' }}>Page</Typography>*/}
        {/*        <Pagination*/}

        {/*            // count={pageCount}*/}
        {/*            count={Math.ceil(getItems/16)}*/}
        {/*            onChange={handleChangePagination}*/}
        {/*            color="success"*/}
        {/*            size="large"*/}
        {/*            sx={{*/}
        {/*                mt: 1,*/}
        {/*                mb: 5,*/}
        {/*                '& .MuiPagination-ul': {*/}
        {/*                    justifyContent: 'center',*/}
        {/*                },*/}
        {/*            }}*/}
        {/*        />*/}
        {/*    </>*/}
        {/*    ) : null*/}
        {/*)}*/}
        <Box sx={{ mt: -3, mb: 2}} >
            {/*<Typography variant="subtitle1" style={{ textAlign: 'left', color: 'grey'}}>{i18n.t("results")}:&nbsp;{products[0].category}</Typography>*/}
            <Typography
                variant="h5"
                style={{
                    textAlign: 'left',
                    // color: 'grey'
                    fontWeight: 'bold',
                }}
                sx={{ textTransform: 'uppercase', }}>
                {/*{i18n.t("category")}:&nbsp;*/}&middot;&nbsp;
                {
                    (() => {
                        if (category === undefined && onFilterCategoryMain === undefined) return null;
                        // console.log("category_switch: ", category);
                        switch (category) {
                        // switch ("") {
                            case "Coltelli trinciastocchi": return <>{i18n.t("coltelliTrinciastocchi")}</>;
                            case "Denti/coltelli per erpice": return <>{i18n.t("dentiColtelliErpice")}</>;
                            case "Dischi": return <>{i18n.t("dischi")}</>;
                            case "Lame carro unifeed": return <>{i18n.t("lameCarroUnifeed")}</>;
                            case "Lame per raccolta mais": return <>{i18n.t("lameRaccoltaMais")}</>;
                            case "Pale": return <>{i18n.t("pale")}</>;
                            case "Punte": return <>{i18n.t("punte")}</>;
                            case "Raschietti": return <>{i18n.t("raschietti")}</>;
                            case "Ripuntatori": return <>{i18n.t("ripuntatori")}</>;
                            case "Zappe per fresatrici": return <>{i18n.t("zappePerFresatrici")}</>;
                            case "Soc": return <>{i18n.t("soc")}</>;
                            case "All" || "": return <>{i18n.t("allCategories")}</>;
                            // default: return products[0].category;
                            // default: return <>{i18n.t("allCategories")}</>;
                            default: return <>{i18n.t("products")}</>;
                        }
                    })()
                }
            </Typography>
        </Box>
        <Divider sx={{mb: 3, mt:1}}/>


      {viewMode === 'grid' ? (
        <Box
          rowGap={4}
          columnGap={3}
          display="grid"
          gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(3, 1fr)' }}
        >
          {products.length !== 0 ? (loading ? [...Array(9)] : products).map((product, index) =>
            product ? (
              <EcommerceProductViewGridItem key={product.id} product={product} />
            ) : (
              <EcommerceProductViewGridItemSkeleton key={index} />
            )
          ): (
              // <Typography variant="h5" style={{ textAlign: 'center', color: 'red' }}>{renderProductsNone()}</Typography>
              <div>{renderProductsNone()}</div>
          )}
        </Box>
      ) : (
        <Stack spacing={4}>
          {products.length !== 0 ? (loading ? [...Array(9)] : products).map((product, index) =>
            product ? (
              <EcommerceProductViewListItem key={product.id} product={product} />
            ) : (
              <EcommerceProductViewListItemSkeleton key={index} />
            )
          ): (
              // <Typography variant="h5" style={{ textAlign: 'center', color: 'red' }}>{renderProductsNone()}</Typography>
              <div>{renderProductsNone()}</div>
          )}
        </Stack>
      )}


        {(Math.ceil(getItems/9) > 1 && products.length !== 0 ? (
            <>

                <Pagination

                    // count={pageCount}
                    count={Math.ceil(getItems/9)}
                    onChange={handleChangePagination}
                    color="success"
                    size="large"
                    sx={{
                        mt: 8,
                        mb: 1,
                        '& .MuiPagination-ul': {
                            justifyContent: 'center',
                        },
                    }}
                />
                <Typography variant="subtitle1" style={{ textAlign: 'center', color: 'grey'}}>Page</Typography>
            </>
            ) : null
        )}


    </>
  );
}

EcommerceProductList.propTypes = {
     loading: PropTypes.bool,
    products: PropTypes.array,
    viewMode: PropTypes.string,
    category: PropTypes.string,
  // onPage: PropTypes.string,
    onPage: PropTypes.func,
    onFilterCategoryMain: PropTypes.func,
  // getItems: PropTypes.string,
    getItems: PropTypes.number,

    productsCategories: PropTypes.array,
};
