import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18n from "i18next";
import {Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// @mui
import { useTheme } from '@mui/material/styles';
import {Box, Stack, Button, AppBar, Toolbar, Container, IconButton, Badge, Link} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LanguageIcon from '@mui/icons-material/Language';

import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import ViewListIcon from '@mui/icons-material/ViewList';
import Tooltip from '@mui/material/Tooltip';
// hooks
import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { bgBlur } from 'src/utils/cssStyles';
// routes
import { paths } from 'src/routes/paths';
// config
import { HEADER } from 'src/config-global';
// components
import Logo from 'src/components/logo';
import Label from 'src/components/label';
import SettingsDrawer from 'src/components/settings/drawer';
//
// import {Link as RouterLink} from "react-router-dom";
import { NavMobile, NavDesktop, navConfig } from '../nav';
import Searchbar from '../../components/Searchbar';
import HeaderShadow from '../../components/HeaderShadow';
import Iconify from "../../../components/iconify";
import AuthHandler from "../../../pages/e-commerce/AuthHandler";
import LanguageSwitcher from "../../../components/language/LanguageSwitcher";




// ----------------------------------------------------------------------

export default function Header({ headerOnDark }) {
  const theme = useTheme();

  const isMdUp = useResponsive('up', 'md');

  const isOffset = useOffSetTop();

  const navigate = useNavigate();

  // const logged = sessionStorage.getItem('logged');
  //   const [logged, setLogged] = useState(sessionStorage.getItem('logged'));
    const [logged, setLogged] = useState(localStorage.getItem('logged'));
    const [lang, setLang] = useState(i18n.language);
    // const [forceRender, setForceRender] = useState(0);

   const handleLogout = () => {
       sessionStorage.setItem('logged', 'false');
       localStorage.setItem('logged', 'false');
       sessionStorage.setItem('login', 'false');
       localStorage.setItem('login', 'false');


       // modify the jwt and refreshToken 13072023
       sessionStorage.removeItem('jwt');
       localStorage.removeItem('jwt');
       sessionStorage.removeItem('refreshToken');
       localStorage.removeItem('refreshToken');

       navigate('/auth/auth-handler');
       // setLogged(sessionStorage.getItem('logged'));
       setLogged(localStorage.getItem('logged'));
   };

    const handleLogin = () => {
        sessionStorage.setItem('login', 'true');
        localStorage.setItem('login', 'true');
        sessionStorage.setItem('logged', 'false');
        localStorage.setItem('logged', 'false');
        navigate('/auth/auth-handler');
        // setLogged(sessionStorage.getItem('logged'));
        setLogged(localStorage.getItem('logged'));
    };

    const handleCatalogue = () => {
        navigate('/');
    };

    // useEffect(() => {
    //     setLogged(sessionStorage.getItem('logged'));
    //     console.log('logged_useState: ', logged);
    //     // setForceRender(prevState => prevState + 1);
    // }, [logged]);


    useEffect(() => {

        // Event handler for when the language changes
        const handleLanguageChange = (lng) => setLang(lng);

        // Add event listener for language change
        i18n.on('languageChanged', handleLanguageChange);

        // Cleanup function (will be called when the component is unmounted)
        return () => {
            // Remove event listener for language change
            i18n.off('languageChanged', handleLanguageChange);
        };

    }, );


  return (

      //   top bar nav
      // <AppBar color="transparent" sx={{ boxShadow: 1, bgcolor: '#eceff1', color: '#004d40' }}>
      //     <Toolbar
      //         disableGutters
      //         sx={{
      //             height: {
      //                 xs: HEADER.H_MOBILE,
      //                 md: HEADER.H_MAIN_DESKTOP,
      //             },
      //             transition: theme.transitions.create(['height', 'background-color'], {
      //                 easing: theme.transitions.easing.easeInOut,
      //                 duration: theme.transitions.duration.shorter,
      //             }),
      //             ...(headerOnDark && {
      //                 color: 'common.white',
      //             }),
      //             ...(isOffset && {
      //                 ...bgBlur({ color: theme.palette.background.default }),
      //                 color: 'text.primary',
      //                 height: {
      //                     md: HEADER.H_MAIN_DESKTOP - 16,
      //                 },
      //             }),
      //         }}
      //     >
      //         <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
      //             <Box sx={{ lineHeight: 0, position: 'relative' }}>
      //
      //                 <Logo />
      //
      //
      //                 {/* <Link href="" target="_blank" rel="noopener"> */}
      //                 {/*  <Label */}
      //                 {/*    color="info" */}
      //                 {/*    sx={{ */}
      //                 {/*      ml: 0.5, */}
      //                 {/*      px: 0.5, */}
      //                 {/*      top: -14, */}
      //                 {/*      left: 140, */}
      //                 {/*      height: 20, */}
      //                 {/*      fontSize: 11, */}
      //                 {/*      cursor: 'pointer', */}
      //                 {/*      position: 'absolute', */}
      //                 {/*    }} */}
      //                 {/*  > */}
      //                 {/*    v1.0 */}
      //                 {/*  </Label> */}
      //                 {/* </Link> */}
      //             </Box>
      //
      //             {isMdUp && <NavDesktop data={navConfig} />}
      //
      //             <Stack
      //                 spacing={2}
      //                 flexGrow={1}
      //                 direction="row"
      //                 alignItems="center"
      //                 justifyContent="flex-end"
      //             >
      //                 <Stack spacing={1} direction="row" alignItems="center">
      //                     {/* <Searchbar /> */}
      //
      //
      //                     {/*  ingranaggio */}
      //                     {/* <SettingsDrawer /> */}
      //
      //
      //
      //
      //                     {/* {!isMdUp && ( */}
      //                     {/*    <IconButton size="small" color="inherit" sx={{ p: 0 }}> */}
      //                     {/*        <Iconify icon="carbon:search" width={24} /> */}
      //                     {/*    </IconButton> */}
      //                     {/* )} */}
      //
      //                     {/* Cuoricino */}
      //                     {/* <Badge badgeContent={2} color="info"> */}
      //                     {/*  <IconButton */}
      //                     {/*    component={RouterLink} */}
      //                     {/*    to={paths.eCommerce.wishlist} */}
      //                     {/*    size="small" */}
      //                     {/*    color="inherit" */}
      //                     {/*    sx={{ p: 0 }} */}
      //                     {/*  > */}
      //                     {/*    <Iconify icon="carbon:favorite" width={24} /> */}
      //                     {/*  </IconButton> */}
      //                     {/* </Badge> */}
      //
      //
      //
      //                     {/*<Tooltip title="HT Catalogue">*/}
      //                     {/*    <Button*/}
      //                     {/*        onClick={handleCatalogue}*/}
      //                     {/*        color="success"*/}
      //                     {/*        startIcon={<ViewListIcon />}*/}
      //                     {/*        sx={{ p: 2 }}*/}
      //                     {/*    >*/}
      //                     {/*        Catalogue*/}
      //                     {/*    </Button>*/}
      //                     {/*</Tooltip>*/}
      //
      //                     <LanguageSwitcher />
      //                     {(logged === 'true') ? (
      //                         <>
      //
      //
      //                             {/*<Tooltip title="Account">*/}
      //                             {/*    <IconButton*/}
      //                             {/*        component={RouterLink}*/}
      //                             {/*        to={paths.eCommerce.account.personal}*/}
      //                             {/*        color="info"*/}
      //                             {/*        sx={{ p: 2 }}*/}
      //                             {/*    >*/}
      //                             {/*        <Iconify icon="carbon:user" width={24} />*/}
      //                             {/*    </IconButton>*/}
      //                             {/*</Tooltip>*/}
      //
      //
      //                             <Tooltip title="Logout">
      //                                 <Button
      //                                     onClick={handleLogout}
      //                                     color="error" // error color
      //                                     aria-label="Logout"
      //                                     startIcon={<LogoutIcon />}
      //                                     sx={{ p: 2 }}
      //                                 >
      //                                     {i18n.t("logout")}
      //                                 </Button>
      //                             </Tooltip>
      //                         </>
      //                       ) : (
      //                           <>
      //
      //                               <Tooltip title="Login">
      //                                   <Button
      //                                       onClick={handleLogin}
      //                                       color="primary" // primary color
      //                                       aria-label="Login"
      //                                       startIcon={<LoginIcon />}
      //                                       sx={{ p: 2 }}
      //                                   >
      //                                       {i18n.t("login")}
      //                                   </Button>
      //                               </Tooltip>
      //
      //                           </>
      //                       )}
      //
      //                     {/* Translate */}
      //                     {/*<LanguageSwitcher />*/}
      //                 </Stack>
      //             </Stack>
      //
      //             {/* {!isMdUp && <NavMobile data={navConfig} />} */}
      //         </Container>
      //     </Toolbar>
      //
      //     {isOffset && <HeaderShadow />}
      // </AppBar>


      <AppBar color="transparent" sx={{ boxShadow: 1, bgcolor: '#eceff1', color: '#004d40' }}>
          <Toolbar
              disableGutters
              sx={{
                  height: {
                      xs: HEADER.H_MOBILE,
                      md: HEADER.H_MAIN_DESKTOP,
                  },
                  transition: theme.transitions.create(['height', 'background-color'], {
                      easing: theme.transitions.easing.easeInOut,
                      duration: theme.transitions.duration.shorter,
                  }),
                  ...(headerOnDark && {
                      color: 'common.white',
                  }),
                  ...(isOffset && {
                      ...bgBlur({ color: theme.palette.background.default }),
                      color: 'text.primary',
                      height: {
                          md: HEADER.H_MAIN_DESKTOP - 16,
                      },
                  }),
              }}
          >
              <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ lineHeight: 0, position: 'relative' }}>

                      <Logo />


                      {/* <Link href="" target="_blank" rel="noopener"> */}
                      {/*  <Label */}
                      {/*    color="info" */}
                      {/*    sx={{ */}
                      {/*      ml: 0.5, */}
                      {/*      px: 0.5, */}
                      {/*      top: -14, */}
                      {/*      left: 140, */}
                      {/*      height: 20, */}
                      {/*      fontSize: 11, */}
                      {/*      cursor: 'pointer', */}
                      {/*      position: 'absolute', */}
                      {/*    }} */}
                      {/*  > */}
                      {/*    v1.0 */}
                      {/*  </Label> */}
                      {/* </Link> */}
                  </Box>

                  {isMdUp && <NavDesktop data={navConfig} />}

                  <Stack
                      spacing={2}
                      flexGrow={1}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                  >
                      <Stack spacing={1} direction="row" alignItems="center">
                          {/* <Searchbar /> */}


                          {/*  ingranaggio */}
                          {/* <SettingsDrawer /> */}




                          {/* {!isMdUp && ( */}
                          {/*    <IconButton size="small" color="inherit" sx={{ p: 0 }}> */}
                          {/*        <Iconify icon="carbon:search" width={24} /> */}
                          {/*    </IconButton> */}
                          {/* )} */}

                          {/* Cuoricino */}
                          {/* <Badge badgeContent={2} color="info"> */}
                          {/*  <IconButton */}
                          {/*    component={RouterLink} */}
                          {/*    to={paths.eCommerce.wishlist} */}
                          {/*    size="small" */}
                          {/*    color="inherit" */}
                          {/*    sx={{ p: 0 }} */}
                          {/*  > */}
                          {/*    <Iconify icon="carbon:favorite" width={24} /> */}
                          {/*  </IconButton> */}
                          {/* </Badge> */}



                          {/*<Tooltip title="HT Catalogue">*/}
                          {/*    <Button*/}
                          {/*        onClick={handleCatalogue}*/}
                          {/*        color="success"*/}
                          {/*        startIcon={<ViewListIcon />}*/}
                          {/*        sx={{ p: 2 }}*/}
                          {/*    >*/}
                          {/*        Catalogue*/}
                          {/*    </Button>*/}
                          {/*</Tooltip>*/}

                          {/*<Button*/}
                          {/*    component="a"*/}
                          {/*    href="https://htsrl.it"*/}
                          {/*    target="_blank"*/}
                          {/*    rel="noopener noreferrer"*/}
                          {/*    color="inherit"*/}
                          {/*    sx={{ p: 2, mr: 2, display: 'flex', alignItems: 'center' }}*/}
                          {/*>*/}
                          {/*    <LanguageIcon sx={{ mr: 1 }} />*/}
                          {/*    Website*/}
                          {/*</Button>*/}

                          <LanguageSwitcher />
                          {(logged === 'true') ? (
                              <>


                                  {/*<Tooltip title="Account">*/}
                                  {/*    <IconButton*/}
                                  {/*        component={RouterLink}*/}
                                  {/*        to={paths.eCommerce.account.personal}*/}
                                  {/*        color="info"*/}
                                  {/*        sx={{ p: 2 }}*/}
                                  {/*    >*/}
                                  {/*        <Iconify icon="carbon:user" width={24} />*/}
                                  {/*    </IconButton>*/}
                                  {/*</Tooltip>*/}


                                  <Tooltip title="Logout">
                                      <Button
                                          onClick={handleLogout}
                                          color="error" // error color
                                          aria-label="Logout"
                                          startIcon={<LogoutIcon />}
                                          sx={{ p: 2 }}
                                      >
                                          {i18n.t("logout")}
                                      </Button>
                                  </Tooltip>
                              </>
                          ) : (
                              <>

                                  <Tooltip title="Login">
                                      <Button
                                          onClick={handleLogin}
                                          color="primary" // primary color
                                          aria-label="Login"
                                          startIcon={<LoginIcon />}
                                          sx={{ p: 2 }}
                                      >
                                          {i18n.t("login")}
                                      </Button>
                                  </Tooltip>

                              </>
                          )}

                          {/* Translate */}
                          {/*<LanguageSwitcher />*/}
                      </Stack>
                  </Stack>

                  {/* {!isMdUp && <NavMobile data={navConfig} />} */}
              </Container>
          </Toolbar>

          {isOffset && <HeaderShadow />}
      </AppBar>


  );
}

Header.propTypes = {
    headerOnDark: PropTypes.bool,
};



//   top bar nav
// <AppBar color="transparent" sx={{ boxShadow: 1, bgcolor: '#eceff1', color: '#004d40' }}>
//     <Toolbar
//         disableGutters
//         sx={{
//             height: {
//                 xs: HEADER.H_MOBILE,
//                 md: HEADER.H_MAIN_DESKTOP,
//             },
//             transition: theme.transitions.create(['height', 'background-color'], {
//                 easing: theme.transitions.easing.easeInOut,
//                 duration: theme.transitions.duration.shorter,
//             }),
//             ...(headerOnDark && {
//                 color: 'common.white',
//             }),
//             ...(isOffset && {
//                 ...bgBlur({ color: theme.palette.background.default }),
//                 color: 'text.primary',
//                 height: {
//                     md: HEADER.H_MAIN_DESKTOP - 16,
//                 },
//             }),
//         }}
//     >
//         <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
//             <Box sx={{ lineHeight: 0, position: 'relative' }}>
//                 <Logo />
//
//                 {/* <Link href="" target="_blank" rel="noopener"> */}
//                 {/*  <Label */}
//                 {/*    color="info" */}
//                 {/*    sx={{ */}
//                 {/*      ml: 0.5, */}
//                 {/*      px: 0.5, */}
//                 {/*      top: -14, */}
//                 {/*      left: 140, */}
//                 {/*      height: 20, */}
//                 {/*      fontSize: 11, */}
//                 {/*      cursor: 'pointer', */}
//                 {/*      position: 'absolute', */}
//                 {/*    }} */}
//                 {/*  > */}
//                 {/*    v1.0 */}
//                 {/*  </Label> */}
//                 {/* </Link> */}
//             </Box>
//
//             {isMdUp && <NavDesktop data={navConfig} />}
//
//             <Stack
//                 spacing={2}
//                 flexGrow={1}
//                 direction="row"
//                 alignItems="center"
//                 justifyContent="flex-end"
//             >
//                 <Stack spacing={1} direction="row" alignItems="center">
//                     {/* <Searchbar /> */}
//
//
//                     {/*  ingranaggio */}
//                     {/* <SettingsDrawer /> */}
//
//
//
//
//                     {/* {!isMdUp && ( */}
//                     {/*    <IconButton size="small" color="inherit" sx={{ p: 0 }}> */}
//                     {/*        <Iconify icon="carbon:search" width={24} /> */}
//                     {/*    </IconButton> */}
//                     {/* )} */}
//
//                     {/* Cuoricino */}
//                     {/* <Badge badgeContent={2} color="info"> */}
//                     {/*  <IconButton */}
//                     {/*    component={RouterLink} */}
//                     {/*    to={paths.eCommerce.wishlist} */}
//                     {/*    size="small" */}
//                     {/*    color="inherit" */}
//                     {/*    sx={{ p: 0 }} */}
//                     {/*  > */}
//                     {/*    <Iconify icon="carbon:favorite" width={24} /> */}
//                     {/*  </IconButton> */}
//                     {/* </Badge> */}
//
//
//                     {logged ? (
//                         <>
//                             {/* carrello */}
//                             {/* <Badge badgeContent={4} color="error"> */}
//                             {/*
//                             <IconButton
//                                 component={RouterLink}
//                                 to={paths.eCommerce.cart}
//                                 size="small"
//                                 color="inherit"
//                                 sx={{ p: 1 }}
//                             >
//                                 <Iconify icon="carbon:shopping-cart" width={24} />
//                             </IconButton>
//                         */}
//                             {/* </Badge> */}
//
//                             {/* user icon */}
//                             <Tooltip title="Account">
//                                 <IconButton
//                                     component={RouterLink}
//                                     to={paths.eCommerce.account.personal}
//                                     size="small"
//                                     color="inherit"
//                                     sx={{ p: 1 }}
//                                 >
//                                     <Iconify icon="carbon:user" width={24} />
//                                 </IconButton>
//                             </Tooltip>
//
//                             {/* logout */}
//                             <Tooltip title="Logout">
//                                 <IconButton
//                                     // component={RouterLink}
//                                     // to="/"
//                                     // state= { logout: true },
//                                     onClick={handleLogout}
//                                     color="primary"
//                                     aria-label="Logout"
//                                     // onClick={onDisconnect}
//                                 >
//                                     <LogoutIcon />
//                                 </IconButton>
//                             </Tooltip>
//
//
//                         </>
//                     ) : (
//                         <>
//                             {/* login */}
//                             <Tooltip title="Login">
//                                 <IconButton
//                                     // component={RouterLink}
//                                     // to={paths.loginBackground}
//                                     onClick={handleLogin}
//                                     color="primary"
//                                     aria-label="Login"
//
//                                     // to={paths.eCommerce.login}
//                                     // onClick={() => onLogin('username', 'password')} // Replace with the actual login function
//                                 >
//                                     <LoginIcon />
//                                 </IconButton>
//                             </Tooltip>
//                         </>
//                     )}
//
//                 </Stack>
//
//
//             </Stack>
//
//             {/* {!isMdUp && <NavMobile data={navConfig} />} */}
//         </Container>
//     </Toolbar>
//
//     {isOffset && <HeaderShadow />}
// </AppBar>