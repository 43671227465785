import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Box, Fab, Link, Typography } from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// components
import Label from 'src/components/label';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
//
import { ProductRating, ProductPrice } from '../../components';

// ----------------------------------------------------------------------

export default function EcommerceProductViewGridItem({ product, sx, ...other }) {

    // console.log("EcommerceProductViewGridItem product: ", product.id_ht);
    const [lang, setLang] = useState(i18n.language);

    useEffect(() => {
        // Event handler for when the language changes
        const handleLanguageChange = (lng) => setLang(lng);

        // Add event listener for language change
        i18n.on('languageChanged', handleLanguageChange);

        // Cleanup function (will be called when the component is unmounted)
        return () => {
            // Remove event listener for language change
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);  // Only run this effect once, when the component mounts



    function getColor(family) {
        switch (family) {
            case "COATED HTITE":
                return '#267e4a';  // Specify the color for COATED WHITE
            case "MIG":
                return 'green';  // Specify the color for MIG
            case "LASER CLADDING":
                return 'green';  // Specify the color for LASER CLADDING
            case "":
                return 'grey';  // Specify the color when no family is specified
            default:
                return 'red';  // Default color if none of the above
        }
    }

    function getFamilyMessage(family) {
        switch (family) {
            case "COATED HTITE":
                return i18n.t("coatedHtitePresent");
            case "MIG":
                return i18n.t("migPresent");
            case "LASER CLADDING":
                return i18n.t("laserCladdingPresent");
            case "":
                return i18n.t("noFamilyPresent");
            default:
                return i18n.t("unknownFamily");

        }
    }



    return (

      <Link
        component={RouterLink}
        to={`${paths.eCommerce.product}?id=${product.id}`}
        sx={{
            p: 1,
            backgroundColor: 'rgba(95,180,144,0.05)',
            borderRadius: '10px',
            // color: 'grey',
            mt:0.5,
            mb:0.5,
            textdecoration: 'none',
            '&:hover': {
                textDecoration: 'none',
                backgroundColor: 'rgba(95,180,144,0.15)',
                transform: 'scale(1.1)',
                transition: 'transform .2s',
            }
        }}
        target="_blank"
    >
    <Stack
      sx={{
        position: 'relative',
        '&:hover .add-to-cart': {
          opacity: 1,
        },
        ...sx,
      }}
      {...other}
    >
        {/*
      {product.label === 'new' && (
        <Label color="info" sx={{ position: 'absolute', m: 1, top: 0, right: 0, zIndex: 9 }}>
          NEW
        </Label>
      )}

      {product.label === 'sale' && (
        <Label color="error" sx={{ position: 'absolute', m: 1, top: 0, right: 0, zIndex: 9 }}>
          SALE
        </Label>
      )}
      */}


      <Box sx={{ position: 'relative', mb: 2 }}>
          {/* ADD TO QUOTATION BUTTON
        <Fab
          component={RouterLink}
          to={paths.eCommerce.product}
          className="add-to-cart"
          color="primary"
          size="medium"
          sx={{
            right: 8,
            zIndex: 9,
            bottom: 8,
            opacity: 0,
            position: 'absolute',
            transition: (theme) =>
              theme.transitions.create('opacity', {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.shortest,
              }),
          }}
        >
          <Iconify icon="carbon:shopping-cart-plus" />
        </Fab>
        */}
        <Image
          // src={product.coverImg}
          src={product.images[0].image}
          sx={{
            flexShrink: 0,
            borderRadius: 1.5,
            bgcolor: 'background.neutral',
            border: '1px solid #e0e0e0',
          }}
        />
      </Box>

      <Stack spacing={0}>
        <Label
            variant="solid"
            color="success"
            line={1}
            sx={{
                fontSize: 15,
                textAlign: "center",
                // backgroundColor: 'rgba(54, 179, 126, 0.56)',
            }}>

            {/* {product.category} */}
            {
                (() => {

                    switch (product.category) {
                        case "Coltelli trinciastocchi": return <>{i18n.t("coltelliTrinciastocchi")}</>;
                        case "Denti/coltelli per erpice": return <>{i18n.t("dentiColtelliErpice")}</>;
                        case "Dischi": return <>{i18n.t("dischi")}</>;
                        case "Lame carro unifeed": return <>{i18n.t("lameCarroUnifeed")}</>;
                        case "Lame per raccolta mais": return <>{i18n.t("lameRaccoltaMais")}</>;
                        case "Pale": return <>{i18n.t("pale")}</>;
                        case "Punte": return <>{i18n.t("punte")}</>;
                        case "Raschietti": return <>{i18n.t("raschietti")}</>;
                        case "Ripuntatori": return <>{i18n.t("ripuntatori")}</>;
                        case "Zappe per fresatrici": return <>{i18n.t("zappePerFresatrici")}</>;
                        case "Soc": return <>{i18n.t("soc")}</>;
                        case "All": return <>{i18n.t("all")}</>;
                        default: return product.category;
                    }
                })()
            }

        </Label>

          {/* <TextMaxLine variant="caption" line={1} sx={{ color: 'text.secondary', fontSize: 14 }}> */}
              {/* {i18n.t("brand")}:&nbsp; */}
              {/* <Box sx={{ color: '#263238', display: 'inline', fontSize: 18, fontStyle: 'italic' }}> */}
              {/*    {product.brand} */}
              {/* </Box> */}

          {/* </TextMaxLine> */}


          {/*<Link component={RouterLink} to={`${paths.eCommerce.product}?id=${product.id}`} color="#00796b" sx={{p:1}} target="_blank">*/}
          {/*   /!* <Link component={RouterLink} to={`${paths.eCommerce.product}?id=${product.id}`} color="#00796b" sx={{p:1}}> *!/*/}
          {/*        <TextMaxLine variant="body1" line={1} sx={{ fontWeight: 'fontWeightMedium', color: '#00796b', typography:'subtitle2'}}>*/}
          {/*            <Box sx={{ color: 'text.secondary', display: 'inline', typography:'subtitle2' }}>*/}
          {/*                /!* HTcode:&nbsp; *!/*/}
          {/*                {i18n.t("brand")}:&nbsp;*/}
          {/*            </Box>*/}
          {/*            {product.brand}*/}
          {/*        </TextMaxLine>*/}

          {/*          <TextMaxLine variant="body1" line={1} sx={{ fontWeight: 'fontWeightMedium', color: '#00796b', typography:'subtitle2'}}>*/}
          {/*              <Box sx={{ color: 'text.secondary', display: 'inline', typography:'subtitle2' }}>*/}
          {/*                  /!* HTcode:&nbsp; *!/*/}
          {/*                  {i18n.t("htCode")}:&nbsp;*/}
          {/*              </Box>*/}
          {/*              {product.id_ht}*/}
          {/*          </TextMaxLine>*/}



          {/*               <TextMaxLine variant="body1" line={1} sx={{ fontWeight: 'fontWeightMedium', color: '#00796b', typography:'subtitle2'}}>*/}
          {/*                   <Box sx={{ color: 'text.secondary', display: 'inline', typography:'subtitle2' }}>*/}
          {/*                       /!* HTcode:&nbsp; *!/*/}
          {/*                       {i18n.t("original")}:&nbsp;*/}
          {/*                   </Box>*/}

          {/*                   {(product.original_code)*/}
          {/*                       ? product.original_code.replace("/", "/\n")*/}
          {/*                       : <span style={{color: "grey", fontStyle:"italic"}}>{i18n.t('onlyHTCode')}</span>*/}
          {/*                   }*/}
          {/*               </TextMaxLine>*/}

          {/*   </Link>*/}


          {/*<Link*/}
          {/*    component={RouterLink}*/}
          {/*    to={`${paths.eCommerce.product}?id=${product.id}`}*/}
          {/*    sx={{*/}
          {/*        p: 1,*/}
          {/*        backgroundColor: 'rgba(95,180,144,0.05)',*/}
          {/*        borderRadius: '10px',*/}
          {/*        // color: 'grey',*/}
          {/*        mt:0.5,*/}
          {/*        mb:0.5,*/}
          {/*        textdecoration: 'none',*/}
          {/*        '&:hover': {*/}
          {/*            textDecoration: 'none',*/}
          {/*            backgroundColor: 'rgba(95,180,144,0.15)',*/}
          {/*        }*/}
          {/*    }}*/}
          {/*    target="_blank"*/}
          {/*>*/}
              <TextMaxLine variant="body1" line={1} sx={{ fontWeight: 'fontWeightMedium', color: 'rgba(37,114,81,1)', typography:'subtitle2'}}>
                  <Box sx={{ color: 'black', display: 'inline', typography:'subtitle2' }}>
                      {i18n.t("brand")}:&nbsp;
                  </Box>
                  {product.brand}
              </TextMaxLine>



              <TextMaxLine variant="body1" line={1} sx={{ fontWeight: 'fontWeightMedium', color: 'rgba(37,114,81,1)', typography:'subtitle2'}}>
                  <Box sx={{ color: 'black', display: 'inline', typography:'subtitle2' }}>
                      {i18n.t("code")}:&nbsp;
                  </Box>
                  {(product.original_code)
                      ? product.original_code.replace("/", "/\n")
                      : <span style={{color: "grey", fontStyle:"italic"}}>{i18n.t('onlyHTCode')}</span>
                  }
              </TextMaxLine>

              <TextMaxLine variant="body1" line={1} sx={{ fontWeight: 'fontWeightMedium', color: 'rgba(37,114,81,1)', typography:'subtitle2'}}>
                  <Box sx={{ color: 'black', display: 'inline', typography:'subtitle2' }}>
                      {i18n.t("htCode")}:&nbsp;
                  </Box>
                  {product.id_ht}
              </TextMaxLine>

                {/*{product.family*/}
                {/*    ?<TextMaxLine variant="body1" line={1} sx={{ fontWeight: 'fontWeightMedium', color: 'rgba(37,114,81,1)', typography:'subtitle2'}}>*/}
                {/*        <Box sx={{ color: 'black', display: 'inline', typography:'subtitle2' }}>*/}
                {/*            {i18n.t("family")}:&nbsp;*/}
                {/*        </Box>*/}
                {/*        {i18n.t("familyPresent")}*/}
                {/*    </TextMaxLine>*/}
                {/*    :<TextMaxLine variant="body1" line={1} sx={{ fontWeight: 'fontWeightMedium', color: 'rgba(37,114,81,1)', typography:'subtitle2'}}>*/}
                {/*        <Box sx={{ color: 'black', display: 'inline', typography:'subtitle2' }}>*/}
                {/*            {i18n.t("family")}:&nbsp;*/}
                {/*        </Box>*/}
                {/*        {i18n.t("familyPresentNot")}*/}
                {/*    </TextMaxLine>*/}
                {/*}*/}

          {/*</Link>*/}


                 {/*{ product.family*/}
                 {/*    ? <Label variant="soft" color="success" sx={{typography:'body2', width: "1", textAlign: "center", backgroundColor: 'rgba(54,179,126,0.26)', color: 'rgb(28,61,47)'}}>*/}
                 {/*         /!* {product.family} *!/*/}
                 {/*        {i18n.t("family")}<br/><b>{i18n.t("familyPresent")}</b>*/}
                 {/*      </Label>*/}
                 {/*    : <Label variant="soft" color="error" sx={{typography:'body2', width: "1", textAlign: "center"}}>*/}
                 {/*           /!* {product.family} *!/*/}
                 {/*           {i18n.t("family")}<br/><b>{i18n.t("familyPresentNot")}</b>*/}
                 {/*      </Label>*/}
                 {/*}*/}


          <Label
              variant="soft"
              sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  typography: 'body2',
                  width: "1",
                  textAlign: "center",
                  minHeight: "4em",
                  backgroundColor: product.family ? 'rgba(54,179,126,0.26)' : undefined,
                  color: product.family ? 'rgb(28,61,47)' : undefined
              }}
          >
            <span
                style={{
                    fontWeight: 500,
                    color: 'rgb(27,80,57)',
                    fontSize: '0.9rem'
                }}
            >
                {/*{i18n.t("family")}*/}
            </span>
              {/*<b style={{ color: product.family ? 'green' : 'red' }}>*/}
              {/*    {product.family*/}
              {/*        ? i18n.t("familyPresent")*/}
              {/*        : i18n.t("familyPresentNot")*/}
              {/*    }*/}
              {/*</b>*/}
              <Typography sx={{ typography: 'subtitle2' }} style={{color: getColor(product.family)}} dangerouslySetInnerHTML={{__html: getFamilyMessage(product.family)}}/>
                  {/* Intentionally left blank because content is set via dangerouslySetInnerHTML */}



          </Label>


          {/* </Link> */}

          {/* <Link component={RouterLink} to={`${paths.eCommerce.product}?id=${product.id}`} color="#00796b"> */}
          {/*  <TextMaxLine variant="body1" line={1} sx={{ fontWeight: 'fontWeightMedium', color: '#00796b', typography:'body1'}}> */}
            {/*      <Box sx={{ color: 'text.secondary', display: 'inline', typography:'body1' }}> */}
            {/*          /!* HTcode:&nbsp; *!/ */}
            {/*          {i18n.t("originalCode")}:&nbsp; */}
            {/*      </Box> */}
            {/*      {product.original_code} */}
            {/*  </TextMaxLine> */}
            {/* </Link> */}




        {/* <ProductPrice price={product.price} priceSale={product.priceSale} /> */}

        {/* <ProductRating rating={product.rating} label={`${product.sold} sold`} /> */}
      </Stack>
    </Stack>
      </Link>
  );
}

EcommerceProductViewGridItem.propTypes = {
  product: PropTypes.shape({
    category: PropTypes.string,
    coverImg: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    priceSale: PropTypes.number,
    rating: PropTypes.number,
    sold: PropTypes.number,
    brand: PropTypes.string,
    id_html: PropTypes.string,
    id_ht: PropTypes.string,
    id: PropTypes.number,
    original_code: PropTypes.string,
      family: PropTypes.string,
    // images: PropTypes.shape({
    //         id: PropTypes.number,
    //         alt_text: PropTypes.string,
    //         image: PropTypes.string,
    //     }),
    images: PropTypes.array,
  }),
  sx: PropTypes.object,
    // id_ht: PropTypes.string,
};
