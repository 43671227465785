import react, {useEffect} from 'react';
// import _mock from "../_mock";

// Retrieve the array from session storage
// const productsString = sessionStorage.getItem('products');
// const products = productsString ? JSON.parse(productsString) : [];
//
// export const _products0 = products.map((product, index) => ({
//     name: product.name,
// } ));

// export const _products0 = [...Array(12)].map((_, index) => ({
// // export const _products = [products.array(12)].map((product, index) => ({
//     id: _mock.id(index),
//     // name: NAME[index],
//     name: "name",
//
//     caption: _mock.text.description(index),
//     // description: DESCRIPTION,
//     description: "description",
//     coverImg: _mock.image.product(index),
//     review: index * 2 + 40,
//     // category: CATEGORIES[index],
//     category: "category",
//     sold: index * 2 + 40,
//     inStock: 100,
//     rating: _mock.number.rating(index),
//     label: ['sale', 'new', 'sale', 'sale'][index] || '',
//     price: _mock.number.price(index),
//     priceSale:
//         [
//             _mock.number.price(1),
//             _mock.number.price(2),
//             _mock.number.price(3),
//             _mock.number.price(4),
//             _mock.number.price(5),
//         ][index] || 0,
//     images: [
//         _mock.image.product(1),
//         _mock.image.product(2),
//         _mock.image.product(3),
//         _mock.image.product(4),
//         _mock.image.product(5),
//         _mock.image.product(6),
//         _mock.image.product(7),
//         _mock.image.product(8),
//     ],
// }));

// // Assuming products are stored in session storage as a JSON string containing the 'results' key
// const storedProducts = sessionStorage.getItem('products');
//
// // Parse the JSON string to an object and access the 'results' key or use an empty array if storedProducts is null or not an array
// let products;
// try {
//     const parsedData = JSON.parse(storedProducts);
//     products = Array.isArray(parsedData.results) ? parsedData.results : [];
// } catch (error) {
//     products = [];
// }
//
// // Map the products to the desired format
// const mappedProducts = products.map((product) => ({
//     id: product.id,
//     name: product.id_ht,
//     category: product.category,
//     // coverImg: product.images[],
// }));
//
// // Export the mapped products in a constant variable called _products0
// export const _products0 = mappedProducts;
//
// // You can now use _products0 as needed
// console.log(_products0);


// Assuming products are stored in session storage as a JSON string containing the 'results' key
const storedProducts = sessionStorage.getItem('products');

// Parse the JSON string to an object and access the 'results' key or use an empty array if storedProducts is null or not an array
let products;
try {
    const parsedData = JSON.parse(storedProducts);
    products = Array.isArray(parsedData.results) ? parsedData.results : [];
} catch (error) {
    products = [];
}



// Map the products to the desired format and include the first image from the 'images' array
// const mappedProducts = products.map((product) => {
//     const image = product.images && product.images.length > 0 ? product.images[0] : null;
//     return {
//         id: product.id,
//         id_ht: product.id_ht,
//         category: product.category,
//         coverImg: product.images[0].image,
//         brand: product.brand,
//         description: product.description,
//     };
// });

// Export the mapped products in a constant variable called _products0

// export const _products0 = mappedProducts;

export const _products0 = (products.map((product, index) => ({
    id: product.id,
    // id_ht: product.id_ht,
    category: product.category,
    coverImg: product.images[0].image,
    brand: product.brand,
    description: product.description,
} )));


// You can now use _products0 as needed
console.log("getProducts: ",_products0);
// console.log("qui");