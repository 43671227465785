import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';
// @mui
import { Stack, Link, Fab , Box} from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// components
import Label from 'src/components/label';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
//
import { ProductRating, ProductPrice } from '../../components';

// ----------------------------------------------------------------------

export default function EcommerceProductViewListItem({ product, ...other }) {


    const [lang, setLang] = useState(i18n.language);

    useEffect(() => {
        // Event handler for when the language changes
        const handleLanguageChange = (lng) => setLang(lng);

        // Add event listener for language change
        i18n.on('languageChanged', handleLanguageChange);

        // Cleanup function (will be called when the component is unmounted)
        return () => {
            // Remove event listener for language change
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);  // Only run this effect once, when the component mounts


  return (
    <Stack
      direction="row"
      sx={{
        position: 'relative',
        '&:hover .add-to-cart': {
          opacity: 1,
        },
      }}
      {...other}
    >   {/*
      {product.label === 'new' && (
        <Label color="info" sx={{ position: 'absolute', m: 1, top: 0, left: 0, zIndex: 9 }}>
          NEW
        </Label>
      )}

      {product.label === 'sale' && (
        <Label color="error" sx={{ position: 'absolute', m: 1, top: 0, left: 0, zIndex: 9 }}>
          SALE
        </Label>
      )}
        */}
        {/* ADD TO QUOTATION BUTTON
      <Fab
        component={RouterLink}
        to={paths.eCommerce.product}
        className="add-to-cart"
        color="primary"
        size="medium"
        sx={{
          right: 8,
          zIndex: 9,
          top: 8,
          opacity: 0,
          position: 'absolute',
          transition: (theme) =>
            theme.transitions.create('opacity', {
              easing: theme.transitions.easing.easeIn,
              duration: theme.transitions.duration.shortest,
            }),
        }}
      >
        <Iconify icon="carbon:shopping-cart-plus" />
      </Fab>
        */}

      <Image
        src={product.images[0].image}
        sx={{
          mr: 2,
          width: 160,
          flexShrink: 0,
          borderRadius: 1.5,
          bgcolor: 'background.neutral',
        }}
      />

      <Stack spacing={1}>
        <Stack spacing={0.5}>
          <TextMaxLine variant="caption" line={1} sx={{ color: 'text.disabled' }}>

              {/* {product.category} */}
              {
                  (() => {

                      switch (product.category) {
                          case "Coltelli trinciastocchi": return <>{i18n.t("coltelliTrinciastocchi")}</>;
                          case "Denti/coltelli per erpice": return <>{i18n.t("dentiColtelliErpice")}</>;
                          case "Dischi": return <>{i18n.t("dischi")}</>;
                          case "Lame carro unifeed": return <>{i18n.t("lameCarroUnifeed")}</>;
                          case "Lame per raccolta mais": return <>{i18n.t("lameRaccoltaMais")}</>;
                          case "Pale": return <>{i18n.t("pale")}</>;
                          case "Punte": return <>{i18n.t("punte")}</>;
                          case "Raschietti": return <>{i18n.t("raschietti")}</>;
                          case "Ripuntatori": return <>{i18n.t("ripuntatori")}</>;
                          case "Zappe per fresatrici": return <>{i18n.t("zappePerFresatrici")}</>;
                          case "Soc": return <>{i18n.t("soc")}</>;
                          case "All": return <>{i18n.t("all")}</>;
                          default: return product.category;
                      }
                  })()
              }

          </TextMaxLine>

            {/* <Link component={RouterLink} to={`${paths.eCommerce.product}?id_ht=${product.id_ht}`} color="#00796b"> */}
            <Link component={RouterLink} to={`${paths.eCommerce.product}?id=${product.id}`} color="#00796b">
            <TextMaxLine variant="h6" line={1} sx={{ color: '#00796b', typography:'h6' }}>
                <Box sx={{ color: 'text.secondary', display: 'inline', typography:'subtitle2'}}>
                    HTcode:&nbsp;
                </Box>
                {product.id_ht}
            </TextMaxLine>
          </Link>
        </Stack>

        {/* <ProductRating rating={product.rating} label={`${product.sold} sold`} /> */}

        <TextMaxLine variant="body2" line={2} sx={{ color: 'text.secondary' }}>
          {i18n.t(product.description)}
        </TextMaxLine>

        <TextMaxLine variant="caption" line={1} sx={{ color: 'text.secondary' }}>
            {i18n.t("brand")}:&nbsp;
            <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 14, fontStyle: 'italic' }}>
                {product.brand}
            </Box>
        </TextMaxLine>

          {/*
        <ProductPrice
          price={product.price}
          priceSale={product.priceSale}
          sx={{ typography: 'h6' }}
        />
        */}
      </Stack>
    </Stack>
  );
}

EcommerceProductViewListItem.propTypes = {
  product: PropTypes.shape({
    caption: PropTypes.string,
    category: PropTypes.string,
    coverImg: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    priceSale: PropTypes.number,
    rating: PropTypes.number,
    sold: PropTypes.number,
    brand: PropTypes.string,
    id_ht: PropTypes.string,
    id: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.shape({
        image: PropTypes.string,
    }),
  }),
};
