import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';
import {Link as RouterLink} from "react-router-dom";
// @mui
import {Box, Button, Divider, Stack, Tooltip, Typography} from '@mui/material';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
// components
import Markdown from 'src/components/markdown';
import Label from "../../../../components/label";

import {paths} from "../../../../routes/paths";
import Iconify from "../../../../components/iconify";

// ----------------------------------------------------------------------

export default function EcommerceProductDetailsDescription({ description, specifications }) {

  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    // Event handler for when the language changes
    const handleLanguageChange = (lng) => setLang(lng);

    // Add event listener for language change
    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup function (will be called when the component is unmounted)
    return () => {
      // Remove event listener for language change
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);  // Only run this effect once, when the component mounts

  function getFamilyMessage(family) {
    switch (family) {
      case "COATED HTITE":
        return i18n.t("coatedHtitePresent");
      case "MIG":
        return i18n.t("migPresent");
      case "LASER CLADDING":
        return i18n.t("laserCladdingPresent");
      case "":
        return i18n.t("noFamilyPresent");
      default:
        return i18n.t("unknownFamily");

    }
  }

  function getColor(family) {
    switch (family) {
      case "COATED HTITE":
        return '#267e4a';  // Specify the color for COATED WHITE
      case "MIG":
        return 'green';  // Specify the color for MIG
      case "LASER CLADDING":
        return 'green';  // Specify the color for LASER CLADDING
      case "":
        return 'grey';  // Specify the color when no family is specified
      default:
        return 'red';  // Default color if none of the above
    }
  }

  return (
    <Stack
      spacing={1}
      sx={{
        py: { xs: 5, md: 10 },
      }}
    >
      <Stack spacing={1}>

        {/* <Divider sx={{ borderStyle: 'dashed', my: 3}} /> */}
         <Typography variant="h5"> {i18n.t("specifications").toUpperCase()}: </Typography>

        {specifications.map((row) => (
          <Stack
            key={row.label}
            // spacing={0.2}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            sx={{ typography: 'body1'}}
          >
            <Box component="span" sx={{ width: 160, color: 'text.secondary', fontStyle: 'italic', typography: 'body1'}}>

              {
                (() => {

                  switch (row.label) {
                    case "HT-Code:": return <>{i18n.t("htCode")}:</>;
                    case "Brand:": return <>{i18n.t("brand")}:</>;
                    case "Width:": return <>{i18n.t("width")}:</>;
                    case "Thickness:": return <>{i18n.t("thickness")}:</>;
                    case "Length:": return <>{i18n.t("length")}:</>;
                    case "Weight:": return <>{i18n.t("weight")}:</>;
                    case "Holes Type:": return <>{i18n.t("holesType")}:</>;
                    case "Holes Number:": return <>{i18n.t("holesNumber")}:</>;
                    case "Holes Diameter:": return <>{i18n.t("holesDiameter")}:</>;
                    case "Holes Spacing:": return <>{i18n.t("holesSpacing")}:</>;
                    case "Original Code:": return <>{i18n.t("code")}:</>;
                    case "Family:": return <>{i18n.t("family")}:</>;
                    default: return row.label;


                  }
                })()
              }

              {/* {row.label} */}
            </Box>
            <Box component="span" sx={{ width: 160, color: 'text.primary', fontStyle: 'normal', typography: 'subtitle1' }}>
              {
                // (() => {
                //   console.log('Value:', row.label + row.value);
                //   switch (row.label) {
                //     case "HT-Code:":
                //       return <Typography sx={{ typography: 'h6' }}>{row.value}</Typography>;
                //     case  "Original Code:":
                //       return <Typography sx={{ typography: 'h5' }}>{row.value}</Typography>;
                //     case "Brand:":
                //       return <Typography sx={{ typography: 'h5' }}>{row.value}</Typography>;
                //     case "Family:":
                //     //  return <Label color="success" sx={{ mb: 3 , mt: 5, typography: 'h5', fontStyle: 'normal', p:2}}>{row.value}&#174;</Label>;
                //         return (row.value && row.value.trim() === "COATED HTITE" ?
                //                 <div style={{}}>
                //                 {i18n.t("present").toUpperCase()}
                //                        <img
                //                              src="/assets/logo/HTite.png" // replace this with your image URL if needed
                //                              alt="Family" // set a suitable alt text
                //                              style={{
                //                                marginBottom: '3px',
                //                                // marginTop: '5px',
                //                                padding: '2px',
                //                                width: '70px',
                //                              }}
                //                          />
                //                 </div>
                //                 :
                //             null
                //         );
                //
                //     default:
                //         return row.value;
                //
                //
                //   }
                // })()
                (() => {
                  console.log('row:', row.label + row.value);
                  switch (row.label) {
                    case "HT-Code:":
                      return <Typography sx={{ typography: 'h6' }}>{row.value}</Typography>;
                    case  "Original Code:":
                      return <Typography sx={{ typography: 'h6' }}>{row.value}</Typography>;
                    case "Brand:":
                      return <Typography sx={{ typography: 'h5' }}>{row.value}</Typography>;
                    case "Family:":
                      // if(row.value && row.value.trim() === "COATED HTITE") {
                      //   return (
                      //       <div>
                      //         {i18n.t("expected").toUpperCase()}
                      //         {/*<img*/}
                      //         {/*    src="/assets/logo/HTite.png" // replace this with your image URL if needed*/}
                      //         {/*    alt="Family" // set a suitable alt text*/}
                      //         {/*    style={{*/}
                      //         {/*      marginBottom: '3px',*/}
                      //         {/*      padding: '2px',*/}
                      //         {/*      width: '70px',*/}
                      //         {/*    }}*/}
                      //         {/*/>*/}
                      //       </div>
                      //   );
                      // }
                      // if(row.value && row.value.trim() !== "" && row.value.trim() !== "COATED HTITE") {
                      //   // Add what you want to return when value is not "COATED HTITE" but not empty
                      //   return (
                      //       <>
                      //         {i18n.t("familyPresent").toUpperCase()}
                      //         {/*<Label color="success" sx={{typography: 'h5', fontStyle: 'normal', p:2}}>*/}
                      //         {/*  {row.value}*/}
                      //         {/*</Label>*/}
                      //       </>
                      //   );
                      // }
                      // // return null;
                      //   return (<>{i18n.t("familyPresentNot").toUpperCase()}<div>{row.value}</div></>);
                          return  <Label
                              variant="soft"
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                typography: 'body2',
                                width: "auto",
                                textAlign: "center",
                                height: 'auto',  // Usa 'auto' per l'altezza
                                backgroundColor: ['COATED HTITE', 'MIG', 'LASER CLADDING'].includes(row.value) ? 'rgba(54,179,126,0.26)' : undefined,
                                color: ['COATED HTITE', 'MIG', 'LASER CLADDING'].includes(row.value) ? 'rgb(28,61,47)' : undefined
                              }}
                          >
                          <span
                              style={{
                                fontWeight: 500,
                                color: 'rgb(27,80,57)',
                                fontSize: '0.9rem'
                              }}
                          >
                            {/*{i18n.t("family")}*/}
                          </span>
                            <Typography
                                sx={{ typography: 'subtitle2' }}
                                style={{
                                  color: getColor(row.value),
                                  whiteSpace: 'normal',  // Permette il wrapping del testo
                                  overflow: 'visible'    // Lascia che il testo espanda il contenitore
                                }}
                                dangerouslySetInnerHTML={{__html: getFamilyMessage(row.value)}}
                            />
                          </Label>


                    case "Holes Type:":
                      if (row.value === "FORI") {
                        return i18n.t("holes")
                      }
                      return i18n.t("buttonholes")

                    default:
                      return row.value;
                  }
                })()


              }
            </Box>
          </Stack>
        ))}
      </Stack>

      <Stack>
        {/* <Typography variant="h6"> Description </Typography> */}
        <Markdown content={description} sx={{ '& p, ul': { typography: 'body2' } }} />
      </Stack>

      {/*<Tooltip title="Add this product to Quotation" placement="top">*/}
      {/*  <Button*/}
      {/*      component={RouterLink}*/}
      {/*      to={paths.eCommerce.cart}*/}
      {/*      // fullWidth={!isMdUp}*/}
      {/*      size="large"*/}
      {/*      color="inherit"*/}
      {/*      variant="contained"*/}
      {/*      startIcon={<RequestQuoteIcon />}*/}
      {/*      sx={{ width: 1 }}*/}
      {/*      disabled*/}
      {/*  >*/}
      {/*    Add to Quotation<i>&nbsp;(disabled)</i>*/}
      {/*  </Button>*/}
      {/*</Tooltip>*/}

      {/* <Label color="error">ERROR. (verify icon)</Label> */}


    </Stack>
  );
}

EcommerceProductDetailsDescription.propTypes = {
  description: PropTypes.string,
  specifications: PropTypes.array,
};
