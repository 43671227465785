// import { Button } from "@mui/material";
// // eslint-disable-next-line import/no-extraneous-dependencies
// import i18n from 'i18next';
// // import USAFlag from "./flags/USAFlag.svg";
// // import GermanyFlag from "./flags/GermanyFlag.svg";
//
// function LanguageSwitcher() {
//
//     const changeLanguage = (language) => {
//         i18n.changeLanguage(language);
//     };
//
//     return (
//         <div>
//             <Button onClick={() => changeLanguage("en")}> EN
//                 {/* <img src={USAFlag} alt="US Flag" width="30" height="20" /> */}
//             </Button>
//             <Button onClick={() => changeLanguage("de")}> DE
//                 {/* <img src={GermanyFlag} alt="Germany Flag" width="30" height="20" /> */}
//             </Button>
//             {/* Add more Buttons for other languages */}
//         </div>
//     );
// }
//
// export default LanguageSwitcher;

// ----------------------------------------------------------------------

// import React, { useState } from "react";
// // eslint-disable-next-line import/no-extraneous-dependencies
// // import { FaFlagUs, FaFlagDe, FaFlagIt, FaFlagFr, FaFlagEs, FaFlagRu } from 'react-icons/fa';
// import { Select, MenuItem } from "@mui/material";
// // eslint-disable-next-line import/no-extraneous-dependencies
// import 'flag-icon-css/css/flag-icons.min.css';
// // import i18n from 'i18next';
// import { useTranslation } from 'react-i18next';
//
// function LanguageSwitcher() {
//     // const [language, setLanguage] = useState(i18n.language);
//     const { i18n } = useTranslation();
//     const [language, setLanguage] = useState(i18n.language || 'en');
//     const changeLanguage = (event) => {
//         setLanguage(event.target.value);
//         i18n.changeLanguage(event.target.value);
//     };
//
//     return (
//         // <Select value={language} onChange={changeLanguage}>
//         //     <MenuItem value="en">English</MenuItem>
//         //     <MenuItem value="de">German</MenuItem>
//         //     <MenuItem value="it">Italiano</MenuItem>
//         //     <MenuItem value="fr">French</MenuItem>
//         //     <MenuItem value="es">Spanish</MenuItem>
//         //     <MenuItem value="ru">Russian</MenuItem>
//         // </Select>
//         <Select value={language} onChange={changeLanguage}>
//             <MenuItem value="en">
//                 <span className="flag-icon flag-icon-gb" style={{marginRight: '8px'}} />
//                 English
//             </MenuItem>
//             <MenuItem value="de">
//                 <span className="flag-icon flag-icon-de" style={{marginRight: '8px'}} />
//                 German
//             </MenuItem>
//             <MenuItem value="it">
//                 <span className="flag-icon flag-icon-it" style={{marginRight: '8px'}} />
//                 Italiano
//             </MenuItem>
//             <MenuItem value="fr">
//                 <span className="flag-icon flag-icon-fr" style={{marginRight: '8px'}} />
//                 French
//             </MenuItem>
//             <MenuItem value="es">
//                 <span className="flag-icon flag-icon-es" style={{marginRight: '8px'}} />
//                 Spanish
//             </MenuItem>
//             <MenuItem value="ru">
//                 <span className="flag-icon flag-icon-ru" style={{marginRight: '8px'}} />
//                 Russian
//             </MenuItem>
//         </Select>
//     );
// }
//
// export default LanguageSwitcher;

// ----------------------------------------------------------------------
// ok - 04/09/2023

// import React, { useState } from "react";
// import { Select, MenuItem } from "@mui/material";
// import 'flag-icon-css/css/flag-icons.min.css';
// import { useTranslation } from 'react-i18next';
//
// function LanguageSwitcher() {
//     const { i18n } = useTranslation();
//     const browserLanguage = navigator.language.split('-')[0] || 'en';
//     const [language, setLanguage] = useState(i18n.language || browserLanguage);
//     const changeLanguage = (event) => {
//         setLanguage(event.target.value);
//         i18n.changeLanguage(event.target.value);
//     };
//
//     return (
//         <Select value={language} onChange={changeLanguage}>
//             <MenuItem value="en">
//                 <span className="flag-icon flag-icon-gb" style={{marginRight: '8px'}} />
//                 English
//             </MenuItem>
//             <MenuItem value="de">
//                 <span className="flag-icon flag-icon-de" style={{marginRight: '8px'}} />
//                 German
//             </MenuItem>
//             <MenuItem value="it">
//                 <span className="flag-icon flag-icon-it" style={{marginRight: '8px'}} />
//                 Italiano
//             </MenuItem>
//             <MenuItem value="fr">
//                 <span className="flag-icon flag-icon-fr" style={{marginRight: '8px'}} />
//                 French
//             </MenuItem>
//             <MenuItem value="es">
//                 <span className="flag-icon flag-icon-es" style={{marginRight: '8px'}} />
//                 Spanish
//             </MenuItem>
//             <MenuItem value="ru">
//                 <span className="flag-icon flag-icon-ru" style={{marginRight: '8px'}} />
//                 Russian
//             </MenuItem>
//         </Select>
//     );
// }
//
// export default LanguageSwitcher;

// ----------------------------------------------------------------------
// test - 04/09/2023

import React, { useState, useEffect } from "react";
import { Select, MenuItem } from "@mui/material";
import 'flag-icon-css/css/flag-icons.min.css';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
    const { i18n, ready } = useTranslation();

    // Checking if the detected language is supported in your app
    const isLanguageSupported = (lang) => ['en', 'de', 'it', 'fr', 'es', 'ru'].includes(lang);

    const browserLanguage = (navigator.language.split('-')[0] || 'en');
    const defaultLanguage = isLanguageSupported(browserLanguage) ? browserLanguage : 'en';

    const [language, setLanguage] = useState(ready ? i18n.language : defaultLanguage);

    useEffect(() => {
        if (ready && !isLanguageSupported(i18n.language)) {
            setLanguage(defaultLanguage);
            i18n.changeLanguage(defaultLanguage);
        }
    }, [ready, i18n, defaultLanguage]);


    const changeLanguage = (event) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
    };

    return (
        <Select value={language} onChange={changeLanguage}>
            <MenuItem value="en">
                <span className="flag-icon flag-icon-gb" style={{marginRight: '7px'}} />
                English
            </MenuItem>
            <MenuItem value="de">
                <span className="flag-icon flag-icon-de" style={{marginRight: '7px'}} />
                German
            </MenuItem>
            <MenuItem value="it">
                <span className="flag-icon flag-icon-it" style={{marginRight: '7px'}} />
                Italiano
            </MenuItem>
            <MenuItem value="fr">
                <span className="flag-icon flag-icon-fr" style={{marginRight: '7px'}} />
                French
            </MenuItem>
            <MenuItem value="es">
                <span className="flag-icon flag-icon-es" style={{marginRight: '7px'}} />
                Spanish
            </MenuItem>
            <MenuItem value="ru">
                <span className="flag-icon flag-icon-ru" style={{marginRight: '7px'}} />
                Russian
            </MenuItem>
        </Select>
    );
}

export default LanguageSwitcher;
