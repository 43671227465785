import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
//
import {
  // // Marketing
  // MarketingBlogPage,
  // MarketingPostPage,
  // MarketingAboutPage,
  // MarketingContactPage,
  // MarketingLandingPage,
  // MarketingServicesPage,
  // MarketingCaseStudyPage,
  // MarketingCaseStudiesPage,
  // // Travel
  // TravelBlogPage,
  // TravelPostPage,
  // TravelTourPage,
  // TravelAboutPage,
  // TravelToursPage,
  // TravelContactPage,
  // TravelLandingPage,
  // TravelCheckoutPage,
  // TravelOrderCompletedPage,
  // // Career
  // CareerJobPage,
  // CareerBlogPage,
  // CareerJobsPage,
  // CareerPostPage,
  // CareerAboutPage,
  // CareerContactPage,
  // CareerLandingPage,
  // // E-Learning
  // ElearningBlogPage,
  // ElearningPostPage,
  // ElearningAboutPage,
  // ElearningCoursePage,
  // ElearningContactPage,
  // ElearningCoursesPage,
  // ElearningLandingPage,
  // E-Commerce
  AuthHandler,
  EcommerceCartPage,
  EcommerceComparePage,
  EcommerceLandingPage,
  EcommerceProductPage,
  EcommerceCheckoutPage,
  EcommerceProductsPage,
  EcommerceWishlistPage,
  EcommerceAccountOrdersPage,
  EcommerceOrderCompletedPage,
  EcommerceAccountPaymentPage,
  EcommerceAccountPersonalPage,
  EcommerceAccountVouchersPage,
  EcommerceAccountWishlistPage,
  // Auth
  LoginCoverPage,
  VerifyCodePage,
  RegisterCoverPage,
  ResetPasswordPage,
  LoginBackgroundPage,
  LoginIllustrationPage,
  RegisterBackgroundPage,
  RegisterIllustrationPage,
  // Common
  Page404,
  Page500,
  HomePage,
  PaymentPage,
  SupportPage,
  Pricing01Page,
  Pricing02Page,
  ComingSoonPage,
  MaintenancePage,
  // Components
  DemoIconsPage,
  DemoImagePage,
  DemoLabelPage,
  ComponentsPage,
  DemoPlayerPage,
  DemoAnimatePage,
  DemoCountUpPage,
  DemoLightboxPage,
  DemoMarkdownPage,
  DemoMegaMenuPage,
  DemoCarouselsPage,
  DemoScrollbarPage,
  DemoTextMaxLinePage,
  DemoNavigationBarPage,
  DemoFormValidationPage,
} from './elements';
// import {EcommerceProductsView} from "../sections/_e-commerce/view";
// import {AuthLoginForm} from "../sections/auth/components";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Non layout
    {
      path: 'auth',
      children: [
        // { path: 'auth-handler', element: <AuthHandler /> },
        { path: 'login-cover', element: <LoginCoverPage /> },
        { path: 'login-illustration', element: <LoginIllustrationPage /> },
        // {path: 'login-background', element: <LoginBackgroundPage />},
        {path: 'login-background', element: <AuthHandler />},
        {path: 'auth-handler', element: <AuthHandler />},
        { path: 'register-cover', element: <RegisterCoverPage /> },
      ],
    },
    // Main layout
    {
      element: <MainLayout />,
      children: [
        // { element: <HomePage />, index: true },
        { path: '/', element: <HomePage /> },
        // { path: '/', element: <EcommerceProductsView/> },
        // { path: '/', element: <EcommerceProductsPage/> },
        // E-Commerce
        {
          path: 'e-commerce',
          children: [
            { path: 'landing', element: <EcommerceLandingPage />},
            { path: 'products', element: <EcommerceProductsPage /> },
            { path: 'product', element: <EcommerceProductPage /> },
            { path: 'cart', element: <EcommerceCartPage /> },
            { path: 'checkout', element: <EcommerceCheckoutPage /> },
            { path: 'order-completed', element: <EcommerceOrderCompletedPage /> },
            { path: 'wishlist', element: <EcommerceWishlistPage /> },
            { path: 'compare', element: <EcommerceComparePage /> },
            {
              path: 'account',
              children: [
                { path: 'personal', element: <EcommerceAccountPersonalPage /> },
                { path: 'wishlist', element: <EcommerceAccountWishlistPage /> },
                { path: 'vouchers', element: <EcommerceAccountVouchersPage /> },
                { path: 'orders', element: <EcommerceAccountOrdersPage /> },
                { path: 'payment', element: <EcommerceAccountPaymentPage /> },
              ],
            },
          ],
        },
        // // Marketing
        // {
        //   path: 'components',
        //   children: [
        //     { element: <ComponentsPage />, index: true },
        //     { path: 'animate', element: <DemoAnimatePage /> },
        //     { path: 'carousel', element: <DemoCarouselsPage /> },
        //     { path: 'count-up', element: <DemoCountUpPage /> },
        //     { path: 'form-validation', element: <DemoFormValidationPage /> },
        //     { path: 'icons', element: <DemoIconsPage /> },
        //     { path: 'image', element: <DemoImagePage /> },
        //     { path: 'label', element: <DemoLabelPage /> },
        //     { path: 'lightbox', element: <DemoLightboxPage /> },
        //     { path: 'markdown', element: <DemoMarkdownPage /> },
        //     { path: 'mega-menu', element: <DemoMegaMenuPage /> },
        //     { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
        //     { path: 'scroll', element: <DemoScrollbarPage /> },
        //     { path: 'player', element: <DemoPlayerPage /> },
        //     { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
        //   ],
        // },
      ],
    },
    // Simple layout
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: 'reset-code', element: <ResetPasswordPage /> },
        { path: 'verify-code', element: <VerifyCodePage /> },
        {
          path: 'auth',
          children: [
            { path: 'reset-code', element: <ResetPasswordPage /> },
            { path: 'verify-code', element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
