import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';
// @mui
import {
    Box,
    Stack,
    Button,
    // Select,
    Divider,
    // MenuItem,
    Container,
    // Typography,
    // FormControl,
    ToggleButton,
    ToggleButtonGroup, Grid, Card, CardMedia, CardContent, Typography,
} from '@mui/material';
// config
import { NAV } from 'src/config-global';


// components
import Iconify from 'src/components/iconify';
//

import EcommerceFilters from '../product/filters';
import { EcommerceProductList, EcommerceProductListBestSellers } from '../product/list';
import ScrollProgress from "../../../components/scroll-progress";



// ----------------------------------------------------------------------

const VIEW_OPTIONS = [
  { value: 'list', icon: <Iconify icon="carbon:list-boxes" /> },
  { value: 'grid', icon: <Iconify icon="carbon:grid" /> },
];

// commentato 13072023
// const SORT_OPTIONS = [
//   { value: 'latest', label: 'Latest' },
//   { value: 'oldest', label: 'Oldest' },
//   { value: 'popular', label: 'Popular' },
// ];

// ----------------------------------------------------------------------

// const verifyToken = async (jwtToken) => {
//     try {
//         const response = await fetch(`${process.env.REACT_APP_MAP_API}/api/token/verify/`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ token: jwtToken })
//         });
//
//         return response.status === 200; // Ritorna true se il token è valido, altrimenti false
//     } catch (error) {
//         console.error("Error verifying JWT token:", error);
//         return false;
//     }
// }
//
// const refreshToken = async (refToken) => {
//     try {
//         const response = await fetch(`${process.env.REACT_APP_MAP_API}/api/token/refresh/`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ refresh: refToken })
//         });
//
//         if (response.status === 200) {
//             const data = await response.json();
//             localStorage.setItem('jwt', data.access);
//             return data.access;
//         }
//         console.error("Error refreshing JWT token:", await response.text());
//         return null;
//
//     } catch (error) {
//         console.error("Error during token refresh:", error);
//         return null;
//     }
// }

const fetchUniqueBrands = async (categoryName, widthName) => {

    // const jwtToken = sessionStorage.getItem('jwt');
    const jwtToken = localStorage.getItem('jwt');
    const headers = {
        'Content-Type': 'application/json',
    };
    if (jwtToken) {
        headers.Authorization = `Bearer ${jwtToken}`;
    } else {
        console.warn('No JWT token found in local storage. Sending request without Authorization header.');
    }
    try {
        // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/`);
        // if (storedCategory === null || storedCategory === 'All' || storedCategory === '') {
        // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/`);

        // const apiUrl = categoryName === 'all' || categoryName === null || categoryName === '' || categoryName === undefined
        //     ? 'http://127.0.0.1:8000/api/products/unique_brands/'
        //     : `http://127.0.0.1:8000/api/products/unique_brands/?category=${categoryName}`;

        // let apiUrl = `${process.env.REACT_APP_MAP_API}/api/products/unique_brands/`;
        let apiUrl = `${process.env.REACT_APP_MAP_API}/api/products/unique_brands_and_count/`;


        // const apiUrl = categoryName === 'all' || categoryName === null || categoryName === '' || categoryName === undefined
        //     // ? 'http://127.0.0.1:8000/api/products/unique_width/'
        //     // : `http://127.0.0.1:8000/api/products/unique_width/?category=${categoryName}`;
        //     ? `${process.env.REACT_APP_MAP_API}/api/products/unique_brands_and_count/`
        //     : `${process.env.REACT_APP_MAP_API}/api/products/unique_brands_and_count/?category=${categoryName}`;

        // Checking if both category and width are defined.
        if (categoryName && widthName) {
            apiUrl += `?category=${categoryName}&width=${widthName}`;
        } else if (categoryName) { // Only category is defined.
            apiUrl += `?category=${categoryName}`;
        } else if (widthName) { // Only width is defined.
            apiUrl += `?width=${widthName}`;
        }


        const response = await fetch(apiUrl,
            {
                method: 'GET',
                headers,
            });

        // const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/?category=${name === 'All' ? '' : name}`);
        const data = await response.json();
        return data;
        // } else {
        //   const response = await fetch(`http://127.0.0.1:8000/api/products/unique_brands/?category=${storedCategory === 'All' ? '' : storedCategory}`);
        //   const data = await response.json();
        //   return data;
        // }

    } catch (error) {
        console.error("Error fetching unique brands:", error);
        return [];
    }
};




export default function EcommerceProductsView() {

    const [mobileOpen, setMobileOpen] = useState(false);
    const [sort, setSort] = useState('latest');
    const [loading, setLoading] = useState(true);
    const [viewMode, setViewMode] = useState('grid');

    const [products, setProducts] = useState([]);
    const [productsCategories, setProductsCategories] = useState([]);
    const [productsCount, setProductsCount] = useState(0);

    const [page, setPage] = useState(1);
    const [filterBrand, setFilterBrand] = useState([]);
    const [filterWidth, setFilterWidth] = useState([]);
    const [filterLength, setFilterLength] = useState([]);
    const [filterThickness, setFilterThickness] = useState([]);

    const [filterCategory, setFilterCategory] = useState("All");
    const [filterCategoryMain, setFilterCategoryMain] = useState("All");

    const [filterSearch, setFilterSearch] = useState("");

    const [lang, setLang] = useState(i18n.language);

    // sessionStorage.setItem('newCategory', filterCategory);


    // useEffect for filterCategory change
    useEffect(() => {

        const fakeLoading = async () => {
            await new Promise((resolve) => setTimeout(resolve, 500));
            setLoading(false);
        };



        const fetchCategories = async () => {
            // const jwtToken = sessionStorage.getItem('jwt');
            const jwtToken = localStorage.getItem('jwt');
            // let jwtToken = localStorage.getItem('jwt');

            const headers = {
                'Content-Type': 'application/json',
            };

            if (jwtToken) {
                headers.Authorization = `Bearer ${jwtToken}`;
            } else {
                console.warn('No JWT token found in local storage. Sending request without Authorization header.');
            }

            // if (jwtToken) {
            //     const isTokenValid = await verifyToken(jwtToken);
            //     if (!isTokenValid) {
            //         console.warn('JWT token is invalid or expired. Trying to refresh...');
            //         const refreshTokenValue = localStorage.getItem('refreshToken');
            //         jwtToken = await refreshToken(refreshTokenValue);
            //
            //         if (!jwtToken) {
            //             console.warn('Unable to refresh JWT token. Sending request without Authorization header.');
            //             return;
            //         }
            //     }
            //
            //     headers.Authorization = `Bearer ${jwtToken}`;
            // } else {
            //     console.warn('No JWT token found in local storage. Sending request without Authorization header.');
            // }

            try {
                const response = await fetch(`${process.env.REACT_APP_MAP_API}/api/categories/`, {
                    method: 'GET',
                    headers,
                });
                const data = await response.json();
                sessionStorage.setItem('productsCategories', JSON.stringify(data.results));
                setProductsCategories(data.results)

                const productsData = {
                    count: 0,
                    next: null,
                    previous: null,
                    results: []
                };
                const productsJSON = JSON.stringify(productsData);
                sessionStorage.setItem('products', []);
                setProducts([]);


            } catch (error) {
                console.error("Error fetching Product Categories:", error);
            }
        };

        const fetchProducts = async () => {
            // const jwtToken = sessionStorage.getItem('jwt');
            const jwtToken = localStorage.getItem('jwt');

            const headers = {
                'Content-Type': 'application/json',
            };

            if (jwtToken) {
                headers.Authorization = `Bearer ${jwtToken}`;
            } else {
                console.warn('No JWT token found in local storage. Sending request without Authorization header.');
            }



            try {
                // let qr = '';
                // if (filterCategoryMain === '') {
                //     qr = `http://127.0.0.1:8000/api/products/?fields=id,id_ht,category,brand,images,description,original_code&page=${page}&category=${filterCategory === 'All' ? '' : filterCategory}&brand=${filterBrand}&width=${filterWidth}&length=${filterLength}&thickness=${filterThickness}&search=${filterSearch}`
                // } else {
                //     qr = `http://127.0.0.1:8000/api/products/?fields=id,id_ht,category,brand,images,description,original_code&page=${page}&category=${filterCategoryMain}&brand=${filterBrand}&width=${filterWidth}&length=${filterLength}&thickness=${filterThickness}&search=${filterSearch}`
                // }

                // Check for specific conditions
                // const response = await fetch(`http://127.0.0.1:8000/api/products/?fields=id,id_ht,category,brand,images,description,original_code&page=${page}&category=${filterCategory}&brand=${filterBrand}&width=${filterWidth}&length=${filterLength}&thickness=${filterThickness}&search=${filterSearch}`, {
                const response = await fetch(`${process.env.REACT_APP_MAP_API}/api/products/?fields=id,id_ht,category,brand,images,description,original_code&page=${page}&category=${filterCategory}&brand=${filterBrand}&width=${filterWidth}&length=${filterLength}&thickness=${filterThickness}&search=${filterSearch}`, {
                // const response = await fetch(qr, {
                    method: 'GET',
                    headers,
                });
                const data = await response.json();
                sessionStorage.setItem('products', JSON.stringify(data));
                setProducts(data.results);
                setProductsCount(data.count);

                // Try to get products Categories from api for landing page
                sessionStorage.setItem('productsCategories', []);
                setProductsCategories([]);
                // setFilterCategoryMain('')

            } catch (error) {
                console.error("Error fetching Products:", error);
            }
        };


        fakeLoading();

        if(filterCategory !== 'All') {
            fetchProducts();
        } else {
            fetchCategories();
        }

        setMobileOpen(false);

        // Event handler for when the language changes
        const handleLanguageChange = (lng) => setLang(lng);

        // Add event listener for language change
        i18n.on('languageChanged', handleLanguageChange);

        // Cleanup function (will be called when the component is unmounted)
        return () => {
            // Remove event listener for language change
            i18n.off('languageChanged', handleLanguageChange);
        };

        // if ((filterCategory === 'start' || filterCategory === 'All' || filterCategory === '') && (filterSearch.length === 0 && filterBrand.length === 0 && filterWidth.length === 0 && filterWidth.length === 0 && filterThickness.length === 0)) {
        //     fetchCategories();
        // } else {
        //     fetchProducts();
        // }

        // if (sessionStorage.getItem('newCategory') !== 'All') {
        //     fetchProducts();
        // } else {
        //     fetchCategories();
        // }

        // if (filterCategory !== 'start' || filterCategory !== 'All') {
        //     fetchProducts();
        // }



    }, [page,filterBrand, filterCategory, filterWidth, filterLength, filterThickness, filterSearch]);


    const handleChangeViewMode = (event, newAlignment) => {
        if (newAlignment !== null) {
            setViewMode(newAlignment);
        }
    };

    const handleChangeSort = (event) => {
        setSort(event.target.value);
    };

    const handleMobileOpen = () => {
        setMobileOpen(true);
    };

    const handleMobileClose = () => {
        setMobileOpen(false);
    };

    const handlePage = (data) => {
        setPage(data);
        // console.log("Page Parent: ", data)
    };

    const handleFilterBrand = (data) => {
        setFilterBrand(data);
        // se filterBrand diverso da data, allora setto page a 1
        if (data !== filterBrand) {
            setPage(1);
        }
        if (data !== '') {
            sessionStorage.setItem('brand', data);
        }
        // console.log("Filter Parent Brand: ", data)
    };

    const handleFilterWidth = (data) => {
        setFilterWidth(data);
        // se filterWidth diverso da data, allora setto page a 1
        if (data !== filterWidth) {
            setPage(1);
        }
        // console.log("Filter Parent Width: ", data)
    }

    const handleFilterLength = (data) => {
        setFilterLength(data);
        // se filterLength diverso da data, allora setto page a 1
        if (data !== filterLength) {
            setPage(1);
        }
        // console.log("Filter Parent Lenght: ", data)
    }


    const handleFilterThickness = (data) => {
        setFilterThickness(data);
        // se filterThickness diverso da data, allora setto page a 1
        if (data !== filterThickness) {
            setPage(1);
        }
        // console.log("Filter Parent Thickness: ", data)
    }


    const handleFilterCategory = (newCategory) => {

        // console.log("handleFilterCategoryTrigger: ", newCategory)
        setFilterCategory(newCategory);
        sessionStorage.setItem('newCategory', newCategory);

    };


    const handleFilterCategoryMain = (newCategoryMain) => {
        // console.log("handleFilterCategoryTriggerMain: ", newCategoryMain)
        setFilterCategoryMain(newCategoryMain);
        sessionStorage.setItem('newCategory', newCategoryMain);
    };

    const handleSearch = (data) => {
        setFilterSearch(data);
        // se filterSearch diverso da data, allora setto page a 1
        if (data !== filterSearch) {
            setPage(1);
        }

        if(filterCategory === 'All' && data.length > 0){
            setFilterCategory('');
        }

        // console.log("Filter Parent Search: ", data)
    }



    const renderProductsCategories = () => (
        // <Typography variant="subtitle1" style={{ textAlign: 'center', color: 'grey'}}>
        //     renderProductsCategories
        // </Typography>

        <>
        {/*<Box sx={{ mt: -5, mb: 2}} >*/}
        {/*    /!*<Typography variant="subtitle1" style={{ textAlign: 'left', color: 'grey'}}>{i18n.t("results")}:&nbsp;{products[0].category}</Typography>*!/*/}
        {/*    <Typography*/}
        {/*        variant="h5"*/}
        {/*        style={{*/}
        {/*            textAlign: 'left',*/}
        {/*            // color: 'grey'*/}
        {/*            fontWeight: 'bold',*/}
        {/*        }}*/}
        {/*        sx={{ textTransform: 'uppercase' }}>*/}
        {/*        /!*{i18n.t("category")}:&nbsp;*!/&middot;&nbsp;*/}
        {/*        {*/}
        {/*            (() => {*/}
        {/*                // if (filterCategory === undefined && onFilterCategoryMain === undefined) return null;*/}
        {/*                // console.log("category_switch: ", filterCategory);*/}
        {/*                switch (filterCategory) {*/}
        {/*                    // switch ("") {*/}
        {/*                    case "Coltelli trinciastocchi": return <>{i18n.t("coltelliTrinciastocchi")}</>;*/}
        {/*                    case "Denti/coltelli per erpice": return <>{i18n.t("dentiColtelliErpice")}</>;*/}
        {/*                    case "Dischi": return <>{i18n.t("dischi")}</>;*/}
        {/*                    case "Lame carro unifeed": return <>{i18n.t("lameCarroUnifeed")}</>;*/}
        {/*                    case "Lame per raccolta mais": return <>{i18n.t("lameRaccoltaMais")}</>;*/}
        {/*                    case "Pale": return <>{i18n.t("pale")}</>;*/}
        {/*                    case "Punte": return <>{i18n.t("punte")}</>;*/}
        {/*                    case "Raschietti": return <>{i18n.t("raschietti")}</>;*/}
        {/*                    case "Ripuntatori": return <>{i18n.t("ripuntatori")}</>;*/}
        {/*                    case "Soc": return <>{i18n.t("soc")}</>;*/}
        {/*                    case "All" || "": return <>{i18n.t("allCategories")}</>;*/}
        {/*                    // default: return products[0].category;*/}
        {/*                    // default: return <>{i18n.t("allCategories")}</>;*/}
        {/*                    default: return <>{i18n.t("products")}</>;*/}
        {/*                }*/}
        {/*            })()*/}
        {/*        }*/}
        {/*    </Typography>*/}
        {/*</Box>*/}
        {/*<Divider sx={{mb: 3, mt:1 }}/>*/}



        {/*<Grid container spacing={2}>*/}
        {/*    {productsCategories && productsCategories.filter(item => item.name !== "All").map((item) => (*/}
        {/*        <Grid item xs={12} sm={viewMode === 'grid' ? 6 : 12} md={viewMode === 'grid' ? 4 : 12} key={item.id}>*/}
        {/*            <Box display="flex" flexDirection="column" height="100%">*/}
        {/*                <Card style={{flexGrow: 1, display: 'flex', flexDirection: viewMode === 'grid' ? 'column' : 'row', backgroundColor: '#f6f6f6'}}>*/}
        {/*                    {item.image && (*/}
        {/*                        <CardMedia*/}
        {/*                            component="img"*/}
        {/*                            height="140"*/}
        {/*                            image={item.image}*/}
        {/*                            alt={item.name}*/}
        {/*                            style={{width: viewMode === 'grid' ? '100%' : '30%', objectFit: 'cover'}}*/}
        {/*                        />*/}
        {/*                    )}*/}

        {/*                    <Box*/}
        {/*                        sx={{*/}
        {/*                            textAlign: 'center',*/}
        {/*                            flexGrow: 1,*/}
        {/*                            cursor: 'pointer',*/}
        {/*                            '&:hover': {*/}
        {/*                                // backgroundColor: '#f3fef5',*/}
        {/*                                backgroundColor: 'rgba(54,179,126,0.21)',*/}
        {/*                                // color: 'rgb(36,82,63)',*/}
        {/*                                '& .zoomable': {*/}
        {/*                                    transform: 'scale(1.1)',*/}
        {/*                                    transition: 'transform 0.3s ease',*/}
        {/*                                    display: 'inline-block'*/}
        {/*                                }*/}
        {/*                            }*/}
        {/*                        }}*/}
        {/*                        component={CardContent}*/}
        {/*                        // onClick={() => onFilterCategoryMain(item.name)}*/}
        {/*                        onClick={() => handleFilterCategory(item.name)}*/}
        {/*                    >*/}
        {/*                        <Typography*/}
        {/*                            variant="h5"*/}
        {/*                            component="div"*/}
        {/*                            className="zoomable"*/}
        {/*                            sx={{*/}
        {/*                                transform: 'scale(1)',*/}
        {/*                                transition: 'transform 0.3s ease',*/}
        {/*                                display: 'inline-block'*/}
        {/*                            }}*/}
        {/*                        >*/}
        {/*                            {*/}
        {/*                                (() => {*/}
        {/*                                    switch (item.name) {*/}
        {/*                                        case "Coltelli trinciastocchi": return <>{i18n.t("coltelliTrinciastocchi")}</>;*/}
        {/*                                        case "Denti/coltelli per erpice": return <>{i18n.t("dentiColtelliErpice")}</>;*/}
        {/*                                        case "Dischi": return <>{i18n.t("dischi")}</>;*/}
        {/*                                        case "Lame carro unifeed": return <>{i18n.t("lameCarroUnifeed")}</>;*/}
        {/*                                        case "Lame per raccolta mais": return <>{i18n.t("lameRaccoltaMais")}</>;*/}
        {/*                                        case "Pale": return <>{i18n.t("pale")}</>;*/}
        {/*                                        case "Punte": return <>{i18n.t("punte")}</>;*/}
        {/*                                        case "Raschietti": return <>{i18n.t("raschietti")}</>;*/}
        {/*                                        case "Ripuntatori": return <>{i18n.t("ripuntatori")}</>;*/}
        {/*                                        case "Soc": return <>{i18n.t("soc")}</>;*/}
        {/*                                        case "All": return <>{i18n.t("all")}</>;*/}
        {/*                                        default: return item.name;*/}
        {/*                                    }*/}
        {/*                                })()*/}
        {/*                            }*/}
        {/*                        </Typography>*/}
        {/*                    </Box>*/}


        {/*                </Card>*/}
        {/*            </Box>*/}
        {/*        </Grid>*/}
        {/*    ))}*/}
        {/*</Grid>*/}

            {/*------------------ ok 05/09/2023 --------------------------------*/}
            <Typography
                variant="h4"
                style={{
                    textAlign: 'left',
                    color: '#3b9871',
                    fontWeight: 'bold',
                }}
                sx={{ textTransform: 'uppercase' }}>
                {/*&middot;&nbsp;select your interest category from HT srl products catalogue*/}
                &middot;&nbsp;{i18n.t("productsCatalogue")}
            </Typography>
            <Divider sx={{mb: 3, mt:1 }}/>
            <Grid container spacing={2}>
                {productsCategories && productsCategories.filter(item => item.name !== "All").map((item) => (
                    <Grid item xs={12} sm={viewMode === 'grid' ? 6 : 12} md={viewMode === 'grid' ? 4 : 12} key={item.id}>
                        <Box display="flex" flexDirection="column" height="100%">
                            <Card
                                style={{flexGrow: 1, display: 'flex', flexDirection: viewMode === 'grid' ? 'column' : 'row', backgroundColor: 'rgba(59,151,113,0.09)', cursor: 'pointer'}}
                                onClick={() => handleFilterCategory(item.name)}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'rgba(54,179,126,0.21)',
                                        '& .zoomable': {
                                            transform: 'scale(1.1)',
                                            transition: 'transform 0.3s ease',
                                        }
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width: viewMode === 'grid' ? '100%' : '30%',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {item.image && (
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={item.image}
                                            alt={item.name}
                                            className="zoomable"
                                        />
                                    )}
                                </Box>

                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        flexGrow: 1,
                                    }}
                                    component={CardContent}
                                >
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        className="zoomable"
                                        sx={{
                                            transform: 'scale(1)',
                                            transition: 'transform 0.3s ease',
                                            display: 'inline-block'
                                        }}
                                    >
                                        {
                                            (() => {
                                                switch (item.name) {
                                                    case "Coltelli trinciastocchi": return <>{i18n.t("coltelliTrinciastocchi")}</>;
                                                    case "Denti/coltelli per erpice": return <>{i18n.t("dentiColtelliErpice")}</>;
                                                    case "Dischi": return <>{i18n.t("dischi")}</>;
                                                    case "Lame carro unifeed": return <>{i18n.t("lameCarroUnifeed")}</>;
                                                    case "Lame per raccolta mais": return <>{i18n.t("lameRaccoltaMais")}</>;
                                                    case "Pale": return <>{i18n.t("pale")}</>;
                                                    case "Punte": return <>{i18n.t("punte")}</>;
                                                    case "Raschietti": return <>{i18n.t("raschietti")}</>;
                                                    case "Ripuntatori": return <>{i18n.t("ripuntatori")}</>;
                                                    case "Zappe per fresatrici": return <>{i18n.t("zappePerFresatrici")}</>;
                                                    case "Soc": return <>{i18n.t("soc")}</>;
                                                    case "All": return <>{i18n.t("all")}</>;
                                                    default: return item.name;
                                                }
                                            })()
                                        }
                                    </Typography>
                                </Box>

                                {/*  start text select brand  */}



                                {/*  stop text select brand  */}


                            </Card>
                        </Box>
                    </Grid>
                ))}
            </Grid>


        {/*  --------------------------- test 05/09/2023 -----------------------------------------------  */}


        </>
    );



  return (
    <>
      <ScrollProgress />

      {/* header 2 */}
      {/* <EcommerceHeader /> */}

      <Container sx={{mt:5}} >
         <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            py: 5,
          }}
         >

          {/* <Typography */}
          {/*    sx={{ */}
          {/*      fontStyle: 'oblique', */}
          {/*      fontWeight: 'medium' */}
          {/*    }}> */}
          {/*  &quot; Innovation and performance you can depend on &quot; */}
          {/* </Typography> */}


         </Stack>

        <Stack
          direction={{
            xs: 'column-reverse',
            md: 'row',
          }}
          sx={{ mb: { xs: 8, md: 10 } }}
        >
          <Stack spacing={5} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>

            {/* This tag for set sx filter */}

              {filterCategory !== "All" &&
                  <EcommerceFilters
                      mobileOpen={mobileOpen}
                      filterCategoryMain={filterCategory}
                      onMobileClose={handleMobileClose}
                      onFilterBrand={handleFilterBrand}
                      onFilterCategory={handleFilterCategory}
                      onFilterWidth={handleFilterWidth}
                      onFilterLength={handleFilterLength}
                      onFilterThickness={handleFilterThickness}
                      onSearch={handleSearch}
                  />
              }




            {/* <EcommerceProductListBestSellers products={_products.slice(0, 3)} /> */}

          </Stack>

          {/*<Box*/}
          {/*  sx={{*/}
          {/*    flexGrow: 1,*/}
          {/*    pl: { md: 8 },*/}
          {/*    width: { md: `calc(100% - ${NAV.W_DRAWER}px)` },*/}
          {/*  }}*/}
          {/*>*/}

             {/*<Stack*/}
             {/* direction="row"*/}
             {/* alignItems="center"*/}
             {/* // justifyContent="space-between"*/}
             {/* justifyContent="flex-end"*/}
             {/* sx={{ mb: 5 }}*/}
             {/*>*/}

             {/*    <Button*/}
             {/*        color="inherit"*/}
             {/*        variant="contained"*/}
             {/*        startIcon={<Iconify icon="carbon:filter" width={18} />}*/}
             {/*        onClick={handleMobileOpen}*/}
             {/*        sx={{*/}
             {/*            display: { md: 'none' },*/}
             {/*        }}*/}
             {/*    >*/}
             {/*        {i18n.t("filters")}*/}
             {/*    </Button>*/}


             {/* /!* change products view quad to single button START *!/*/}
             {/* /!*<ToggleButtonGroup*!/*/}
             {/* /!*  exclusive*!/*/}
             {/* /!*  size="medium"*!/*/}
             {/* /!*  value={viewMode}*!/*/}
             {/* /!*  onChange={handleChangeViewMode}*!/*/}
             {/* /!*  sx={{ borderColor: 'transparent' }}*!/*/}
             {/* /!*>*!/*/}
             {/* /!*  {VIEW_OPTIONS.map((option) => (*!/*/}
             {/* /!*    <ToggleButton key={option.value} value={option.value}>*!/*/}
             {/* /!*      {option.icon}*!/*/}
             {/* /!*    </ToggleButton>*!/*/}
             {/* /!*  ))}*!/*/}
             {/* /!*</ToggleButtonGroup>*!/*/}





             {/* /!* change products view quad to single button STOP *!/*/}

             {/* /!* high right filter button START *!/*/}
             {/*  /!**/}
             {/* <FormControl size="small" hiddenLabel variant="filled" sx={{ width: 120 }}>*/}
             {/*   <Select*/}
             {/*     value={sort}*/}
             {/*     onChange={handleChangeSort}*/}
             {/*     MenuProps={{*/}
             {/*       PaperProps: {*/}
             {/*         sx: { px: 1 },*/}
             {/*       },*/}
             {/*     }}*/}
             {/*   >*/}
             {/*     {SORT_OPTIONS.map((option) => (*/}
             {/*       <MenuItem key={option.value} value={option.value}>*/}
             {/*         {option.label}*/}
             {/*       </MenuItem>*/}
             {/*     ))}*/}
             {/*   </Select>*/}
             {/* </FormControl>*/}
             {/* *!/*/}
             {/* /!* high right filter button STOP *!/*/}

             {/*</Stack>*/}


                {/*<EcommerceProductList*/}
                {/*  loading={loading}*/}
                {/*  viewMode={viewMode}*/}
                {/*  // products={_products.slice(0, 12)}*/}
                {/*  // products={_products0.slice(0, 12)}*/}
                {/*  // products={_products0}*/}
                {/*  products={products}*/}
                {/*  productsCategories={productsCategories}*/}
                {/*  category={filterCategory}*/}
                {/*  onPage={handlePage}*/}
                {/*  getItems={productsCount}*/}
                {/*  // onFilterCategoryMain={handleFilterCategoryMain}*/}
                {/*  onFilterCategoryMain={handleFilterCategory}*/}

                {/*/>*/}

              {
                  filterCategory !== "All"
                      ? (

                          <Box
                              sx={{
                                  flexGrow: 1,
                                  pl: { md: 8 },
                                  width: { md: `calc(100% - ${NAV.W_DRAWER}px)` },
                              }}
                          >
                              <Stack
                                  direction="row"
                                  alignItems="center"
                                  // justifyContent="space-between"
                                  justifyContent="flex-end"
                                  sx={{ mb: 5 }}
                              >
                                  <Button
                                      color="inherit"
                                      variant="contained"
                                      startIcon={<Iconify icon="carbon:filter" width={18} />}
                                      onClick={handleMobileOpen}
                                      sx={{
                                          display: { md: 'none' },
                                      }}
                                  >
                                      {i18n.t("filters")}
                                  </Button>

                              </Stack>


                              <EcommerceProductList
                                  loading={loading}
                                  viewMode={viewMode}
                                  // products={_products.slice(0, 12)}
                                  // products={_products0.slice(0, 12)}
                                  // products={_products0}
                                  products={products}
                                  productsCategories={productsCategories}
                                  category={filterCategory}
                                  onPage={handlePage}
                                  getItems={productsCount}
                                  // onFilterCategoryMain={handleFilterCategoryMain}
                                  onFilterCategoryMain={handleFilterCategory}

                              />
                          </Box>
                      )
                      :
                      <Box
                          sx={{
                              flexGrow: 1,
                              pl: { md: 0 },
                              width: { md: `calc(100% - ${NAV.W_DRAWER}px)` },
                          }}
                      >
                          {renderProductsCategories()}
                      </Box>
              }


          {/*</Box>*/}
        </Stack>
      </Container>
      <Divider sx={{ borderStyle: 'dashed', my: 3, mt:5, mb:5}} />
    </>
  );
}

// EcommerceProductsView.propTypes = {
//   props: PropTypes.number,
//   getItems: PropTypes.number,
// }
// Block.propTypes = {
//   // children: PropTypes.node,
//   // title: PropTypes.string,
//   onFilterBrand: PropTypes.arrayOf(PropTypes.string),
//   onFilterCategory: PropTypes.string,
// };
// <EcommerceProductListBestSellers products={_products.slice(0, 3)} />
