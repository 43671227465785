import React, { useState, useEffect, useCallback } from 'react';

import i18n from 'i18next';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies


import { Link as RouterLink } from 'react-router-dom';
// @mui
import {Link, Stack, Divider, Typography, Box, Button} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// routes
import { paths } from 'src/routes/paths';
// utils
import { bgGradient } from 'src/utils/cssStyles';
//
import { AuthWithSocial, AuthLoginForm } from '../components';
import Label from "../../../components/label";
import LanguageSwitcher from "../../../components/language/LanguageSwitcher";




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.background.default, 0.9),
    imgUrl: '/assets/background/overlay_1.jpg',
  }),
  display: 'flex',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 2),
    marginTop: theme.spacing(6),
}));

// ----------------------------------------------------------------------

export default function LoginBackgroundView({ onLogin, loginFailed }) {

    const [lang, setLang] = useState(i18n.language);

    const handleLogin = (email, password) => {
        if (onLogin) {
            // console.log('LoginBackgroundView.handleLogin: email=', email, 'password=', password);
            onLogin(email, password);
        }
    };

    LoginBackgroundView.propTypes = {
        onLogin: PropTypes.func.isRequired,
        loginFailed: PropTypes.array,
    };


    useEffect(() => {

        // Event handler for when the language changes
        const handleLanguageChange = (lng) => setLang(lng);

        // Add event listener for language change
        i18n.on('languageChanged', handleLanguageChange);

        // Cleanup function (will be called when the component is unmounted)
        return () => {
            // Remove event listener for language change
            i18n.off('languageChanged', handleLanguageChange);
        };

    }, );


  return (
    <>
    <StyledRoot>
      <Stack
        spacing={0}
        sx={{
            mt: -55,
          p: 4,
          width: 1,
          mx: 'auto',
          flexShrink: 0,
          maxWidth: 400,
          borderRadius: 2,
          bgcolor: 'background.default',
          textAlign: { xs: 'center', md: 'left' },
          boxShadow: (theme) => theme.customShadows.z24,
        }}
      >
          {/* Translate */}
          <LanguageSwitcher />
        <div style={{marginTop: 15}}>

          <Typography variant="h3" paragraph>
              {i18n.t("auth")}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 4}}>
              {i18n.t("no_account")}
            <Link
              // component={RouterLink}
              // to={paths.registerBackground}
                href='https://htsrl.it/pagina-registrazione/'
                target='_blank'
                variant="subtitle2"
                color="primary"
                style={{marginLeft: 5}}
            >
                {i18n.t("sign_in")}

            </Link>
          </Typography>

        </div>

        <AuthLoginForm onFormSubmit={handleLogin} loginFailed={loginFailed}/>

          <Button
              component={RouterLink}
              to="/"
              color="inherit"
              aria-label="go to homepage"
              sx={{ lineHeight: 0, mt: 3, p: 2 }}
          >
              {/*<Box*/}
              {/*    sx={{*/}
              {/*        lineHeight: 0,*/}
              {/*        cursor: 'pointer',*/}
              {/*        display: 'inline-flex',*/}
              {/*        maxWidth: '100%',*/}
              {/*        mt: 3,*/}
              {/*    }}*/}
              {/*>*/}
              {i18n.t("rt_home")}
              {/*</Box>*/}

              {/*<Typography*/}
              {/*    sx={{*/}
              {/*        fontWeight: 'medium',*/}
              {/*        fontSize: '0.7rem',*/}
              {/*    }}*/}
              {/*>*/}
              {/*    /!* "Innovation and performance" *!/*/}
              {/*    /!* Innovation and Performance *!/*/}
              {/*</Typography>*/}
          </Button>


        {/* <Divider> */}
        {/*  <Typography variant="body2" sx={{ color: 'text.disabled' }}> */}
        {/*    or continue with */}
        {/*  </Typography> */}
        {/* </Divider> */}

        {/* <AuthWithSocial /> */}


      </Stack>

    </StyledRoot>
    </>
  );
}
