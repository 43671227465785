// @mui
import PropTypes from 'prop-types';
import { Checkbox, Stack, FormControlLabel, Typography } from '@mui/material';


// ----------------------------------------------------------------------

export default function EcommerceFilterWidth({
                                                 // options,
                                                 optionsCount,
                                                 filterWidth,
                                                 onChangeWidth,
                                                 ...other
}) {

    // console.log('optionsCount', optionsCount);

    return (
        <Stack {...other}>
            {/* {options.map((option) => ( */}
            {optionsCount.map((option) => (
                <FormControlLabel
                    key={option.width}
                    control={
                        <Checkbox
                            size="small"
                            value={option.width}
                            checked={filterWidth.includes(option.width)}
                            onChange={() => onChangeWidth(option.width)}
                        />
                    }
                    // label={`${option} mm`}
                    // label={`${option.width} mm (${option.total_products})`}
                    label={

                        <Typography
                            component="div"
                            sx={{
                                fontSize: 13,
                                ...(filterWidth.includes(option.width) && {
                                    fontWeight: 700,
                                }),
                            }}
                        >
                            {option.width} mm<Typography component="span" sx={{fontStyle: 'italic', fontSize: 9, ml: 0.1}}>({option.total_products})</Typography>
                        </Typography>

                    }
                />
            ))}
        </Stack>
    );
}

EcommerceFilterWidth.propTypes = {
    filterWidth: PropTypes.array,
    onChangeWidth: PropTypes.func,
    // options: PropTypes.array,
    optionsCount: PropTypes.array
};