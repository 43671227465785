import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';

// @mui
import { Stack, Typography} from '@mui/material';
// components
import Iconify from 'src/components/iconify';



// ----------------------------------------------------------------------

export default function EcommerceFilterCategory({
  // options,
  optionsCount,
  filterCategories,
  onChangeCategories,
  ...other
}) {

    const [lang, setLang] = useState(i18n.language);

    useEffect(() => {
      // Event handler for when the language changes
      const handleLanguageChange = (lng) => setLang(lng);

      // Add event listener for language change
      i18n.on('languageChanged', handleLanguageChange);

      // Cleanup function (will be called when the component is unmounted)
      return () => {
        // Remove event listener for language change
        i18n.off('languageChanged', handleLanguageChange);
      };
    }, []);  // Only run this effect once, when the component mounts


    return (
    <Stack spacing={1} alignItems="flex-start" {...other} >
      {optionsCount.map((option, index) => (

        <Stack
          key={index}
          direction="row"
          alignItems="center"
          onClick={() => onChangeCategories(option.category)}
          sx={{
              typography: 'body2',
              cursor: 'pointer',
              ...(sessionStorage.getItem('newCategory') === option.category && {
                  fontWeight: 700,
                  borderBottom: '3px solid #fa541c',
              }),
          }}
        >
          <Iconify icon="carbon:chevron-right" width={12} sx={{ mr: 1 }} />
            {/* option.category */}



            {
                (() => {

                    switch (option.category) {
                        case "Coltelli trinciastocchi": return <>{i18n.t("coltelliTrinciastocchi")}</>;
                        case "Denti/coltelli per erpice": return <>{i18n.t("dentiColtelliErpice")}</>;
                        case "Dischi": return <>{i18n.t("dischi")}</>;
                        case "Lame carro unifeed": return <>{i18n.t("lameCarroUnifeed")}</>;
                        case "Lame per raccolta mais": return <>{i18n.t("lameRaccoltaMais")}</>;
                        case "Pale": return <>{i18n.t("pale")}</>;
                        case "Punte": return <>{i18n.t("punte")}</>;
                        case "Raschietti": return <>{i18n.t("raschietti")}</>;
                        case "Ripuntatori": return <>{i18n.t("ripuntatori")}</>;
                        case "Zappe per fresatrici": return <>{i18n.t("zappePerFresatrici")}</>;
                        case "Soc": return <>{i18n.t("soc")}</>;
                        case "All": return <>{i18n.t("all")}</>;
                        default: return option.category;
                    }
                })()
            }



          {/*<Typography sx={{fontStyle: 'italic', fontSize: 10, ml: 1}}>{option.total_products}</Typography>*/}

            <Typography sx={{fontStyle: 'italic', fontSize: 9, ml: 0.1}}>({option.total_products})</Typography>
        </Stack>
      ))}

    </Stack>
  );
}

EcommerceFilterCategory.propTypes = {
  filterCategories: PropTypes.string,
  onChangeCategories: PropTypes.func,
  options: PropTypes.array,
  optionsCount: PropTypes.array,

  // optionsCount: PropTypes.array,
};
