import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect, useCallback } from 'react';

// sections

import {EcommerceProductsView} from "../sections/_e-commerce/view";


// ----------------------------------------------------------------------
// <HomeView />
export default function HomePage() {

  return (
    <>
        <Helmet>
            <title>HT SRL | Products</title>
        </Helmet>
        <EcommerceProductsView />
    </>
  );
}
