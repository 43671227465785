// @mui
import PropTypes from 'prop-types';
import { Checkbox, Stack, FormControlLabel, Typography } from '@mui/material';


// ----------------------------------------------------------------------

export default function EcommerceFilterLength({
                                                 // options,
                                                 optionsCount,
                                                 filterLength,
                                                 onChangeLength,
                                                 ...other
                                             }) {

    // console.log('optionsCount', optionsCount);

    return (
        <Stack {...other}>
            {/* {options.map((option) => ( */}
            {optionsCount.map((option) => (
                <FormControlLabel
                    key={option.length}
                    control={
                        <Checkbox
                            size="small"
                            value={option.length}
                            checked={filterLength.includes(option.length)}
                            onChange={() => onChangeLength(option.length)}
                        />
                    }
                    // label={`${option} mm`}
                    // label={`${option.width} mm (${option.total_products})`}
                    label={

                        <Typography
                            component="div"
                            sx={{
                                fontSize: 13,
                                ...(filterLength.includes(option.length) && {
                                    fontWeight: 700,
                                }),
                            }}
                        >
                            {option.length} mm<Typography component="span" sx={{fontStyle: 'italic', fontSize: 9, ml: 0.1}}>({option.total_products})</Typography>
                        </Typography>

                    }
                />
            ))}
        </Stack>
    );
}

EcommerceFilterLength.propTypes = {
    filterLength: PropTypes.array,
    onChangeLength: PropTypes.func,
    // options: PropTypes.array,
    optionsCount: PropTypes.array
};